import React, {useRef, useState} from "react";
import "./GetCode.css"
import close from "../../assets/img/close.svg"

function GetCode(props) {
    const textAreaRef = useRef(null);
    const [modalStatus, setModalStatus] = useState(false)

    const copyToClipboard = () => {
        textAreaRef.current.select();
        document.execCommand('copy');
    }

    return (
        <div className="getCode">
            <button
                className="getCode-button"
                onClick={() => setModalStatus(true)}
            >
                {props.language.getScript}
            </button>
            <div className={`${modalStatus ? "showModal" : ""} getCode-modal`}>
                <div className="getCode-modal-blank">
                    <img
                        className="getCode-modal-blank-close"
                        src={close}
                        alt="Close"
                        onClick={() => setModalStatus(false)}
                    />
                    <h3 className="getCode-modal-blank-title">
                        {props.language.getScript}
                    </h3>
                    <textarea
                        readOnly
                        cols="75"
                        rows="1"
                        ref={textAreaRef}
                        className="getCode-modal-blank-code_handler"
                        // value={`<script src="https://copointer.com:${props.data.port}/api.js" data-apikey="${props.data.apikey}"></script>`}
                        value={`<script async src="https://copointer.com:${props.data.port}/api.js?apikey=${props.data.apikey}"></script>`}
                    />
                    <button
                        className="getCode-modal-blank-copy_button"
                        onClick={copyToClipboard}
                    >
                        {props.language.copy}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GetCode