import React, {useEffect, useRef} from "react";
import "./Chat.css"
import close from "../../assets/img/close.svg"
import send from "../../assets/img/send.svg"
import {lpack} from "../Language/Language";
import messageStatus from "../../assets/img/read.svg"

function Chat(props) {
    const [msg, getMsg] = React.useState([])
    const [value, setValue] = React.useState('')
    const [editMessageStatus, setEditMessageStatus] = React.useState(false)
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView()
    }

    const getInputValue = event => {
        setValue(event.target.value)
    }

    const getMessage = event => {
        let time = new Date().toTimeString()
        event.persist()
        if (event.target.value === '') {
            return null
        } else if (/^\s+$/.test(event.target.value)) {
            return null
        } else {
            let manager = {
                "managerMessage": event.target.parentElement.firstChild.firstChild.value,
                "roomId": props.activeRoomId,
                "managerDate": time.slice(0, 8),
                "id": 0,
                "messageId": Math.random().toString()
            }
            props.getAllMessages(manager)
            props.realTimeMessage.push(manager)
            props.wss.send(JSON.stringify({
                method: "managerMessage",
                room: props.activeRoomId,
                from: localStorage.getItem('managerName'),
                message: event.target.parentElement.firstChild.firstChild.value,
                messageId: manager.messageId
            }))
            getMsg(prevState => [...prevState, manager])
            setValue('')
            document.querySelectorAll('.chat-form-input')
                .forEach(item => item.focus())
        }
    }

    const onKeyDownHandler = event => {
        let time = new Date().toTimeString()
        event.persist()
        if (event.keyCode === 13) {
            if (event.target.value === '') {
                return null
            } else if (/^\s+$/.test(event.target.value)) {
                return null
            } else {
                let manager = {
                    "managerMessage": event.target.value,
                    "roomId": props.activeRoomId,
                    "managerDate": time.slice(0, 8),
                    "id": 0,
                    "support": 1,
                    "from": localStorage.getItem('managerName'),
                    "messageId": Math.random().toString()
                }
                props.getAllMessages(manager)
                props.realTimeMessage.push(manager)
                props.wss.send(JSON.stringify({
                    method: "managerMessage",
                    room: props.activeRoomId,
                    from: localStorage.getItem('managerName'),
                    message: event.target.value,
                    messageId: manager.messageId
                }))
                getMsg(prevState => [...prevState, manager])
                setValue('')
                document.querySelectorAll('.chat-form-input')
                    .forEach(item => item.focus())
            }
        }
    }

    const timeConverter = time => {
        let oldDate = time.slice(-8)
        let firstPart = oldDate.slice(0, 2)
        let secondPart = oldDate.slice(2)
        let newDate = firstPart === "23"
            ? (parseInt(firstPart) - 22) + secondPart
            : (parseInt(firstPart) + 2) + secondPart
        return newDate
    }

    const deleteMessageHandler = event => {
        props.wss.send(JSON.stringify({
            method: "deleteChatMessage",
            room: props.activeRoomId,
            messageId: event.target.parentElement.parentElement.firstChild.id
        }))
        props.removeManagerMessage(event.target.parentElement.parentElement.firstChild.id)
    }

    const editMessageHandler = event => {
        setEditMessageStatus(!editMessageStatus)
        event.target.parentElement.parentElement.firstChild.lastChild.setAttribute('contentEditable', !editMessageStatus)
        if (!editMessageStatus) {
            event.target.innerHTML = lpack.saveMessage
            event.target.parentElement.parentElement.firstChild.lastChild.style.borderBottom = '1px solid rgb(255, 199, 77)'
            event.target.parentElement.parentElement.firstChild.lastChild.focus()
        } else {
            event.target.innerHTML = lpack.editMessage
            event.target.parentElement.parentElement.firstChild.lastChild.style.borderBottom = 'unset'
            props.wss.send(JSON.stringify({
                method: "editChatMessage",
                room: props.activeRoomId,
                messageId: event.target.parentElement.parentElement.firstChild.id,
                editedMessage: event.target.parentElement.parentElement.firstChild.lastChild.innerHTML
            }))
        }
    }

    useEffect(() => document.querySelectorAll('.chat-form-input')
        .forEach(item => item.focus()), [])

    useEffect(() => {
        scrollToBottom()
    }, [props.realTimeMessage, msg, props.editedMessages, props.deletedMessages]);

    useEffect(() => {
        if (document.querySelector('.chat-header-user_typing')) {
            document.querySelector('.chat-header-user_typing').style.opacity = "1"
            setTimeout(() => {
                document.querySelector('.chat-header-user_typing').style.opacity = "0"
            }, 2000)
        }
    }, [props.typingUser])

    return (
        <div className="chat">
            <div className="chat-header">
                <img
                    className="chat-header-close"
                    src={close}
                    alt="Close"
                    onClick={props.close}
                />
                <p className="chat-header-title">
                    {lpack.chatTitle}
                </p>
                <span className="chat-header-user_typing">
                    {props.typingUser.map(user =>
                        user.roomId === props.activeRoomId
                            ? user.username
                            : null
                    )[props.typingUser.length - 1] + "..."}
                </span>
                <a className="chat-header-link" target="_blank" href={props.joinLink}>{lpack.joinThisChat}</a>
            </div>
            <div className="chat-main">
                {
                    props.realTimeMessage
                        .filter(item => !props.deletedMessages.includes(item.messageId) && item)
                        .filter(item => props.activeRoomId === item.roomId ? item : null)
                        .map((item, index) => {
                            if (item.id !== 0) {
                                return (
                                    <div className="manager_message">
                                        <div
                                            className="message_left"
                                            key={index}
                                            id={item.messageId}
                                        >
                                            <div className="message_left-info">
                                            <span className="message_left-info-name">
                                                {item.from}
                                            </span>
                                            </div>
                                            <p className="message_left-text">
                                                {
                                                    props.editedMessages.length !== 0
                                                        ? props.editedMessages.map(msg =>
                                                            msg.messageId === item.messageId
                                                                ? msg.editedMessage
                                                                : item.message
                                                        )[props.editedMessages.length - 1]
                                                        : item.message
                                                }
                                            </p>
                                        </div>
                                        <div className="message_left-button_handler">
                                            <p className="message_right-button_handler-date">
                                                {timeConverter(item.date)}
                                            </p>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="manager_message">
                                        <div
                                            className="message_right"
                                            key={index}
                                            id={item.messageId}
                                        >
                                            <div className="message_right-info">
                                            <span className="message_right-info-name">
                                                {localStorage.getItem('managerName')}
                                            </span>
                                            </div>
                                            <p className="message_right-text">
                                                {
                                                    item.managerMessage
                                                        ? item.managerMessage
                                                        : item.message
                                                }
                                            </p>
                                        </div>
                                        <div className="message_right-button_handler">
                                            <p
                                                className="message_right-button_handler-delete"
                                                onClick={deleteMessageHandler}
                                            >
                                                {lpack.deleteMessage}
                                            </p>
                                            <p
                                                className="message_right-button_handler-edit"
                                                onClick={editMessageHandler}
                                            >
                                                {lpack.editMessage}
                                            </p>
                                            <p className="message_right-button_handler-date">
                                                {
                                                    item.managerDate
                                                        ? item.managerDate
                                                        : timeConverter(item.date)
                                                }
                                            </p>
                                            <img src={messageStatus} alt="Message status"/>
                                        </div>
                                    </div>
                                )
                            }
                        })
                }
                <div ref={messagesEndRef}/>
            </div>
            <div id="form" className="chat-form" onKeyDown={onKeyDownHandler}>
                <label className="chat-form-input_label">
                    <input
                        type="text"
                        name="message"
                        spellCheck="true"
                        autoComplete="off"
                        className="chat-form-input"
                        placeholder="Напишите сообщение ..."
                        value={value}
                        onChange={getInputValue}
                    />
                </label>
                <img
                    src={send}
                    alt="Send"
                    id="send"
                    className="chat-form-send_img"
                    onClick={getMessage}
                />
            </div>
        </div>
    )
}

export default Chat