import React, {useEffect, useState} from "react";
import "../ChatRoomsHandler.css"
import desktop from "../../../assets/img/desktop.svg";
import tablet from "../../../assets/img/tablet.svg";
import phone from "../../../assets/img/phone.svg";
import close from "../../../assets/img/close.svg";
import activeChat from "../../../assets/img/active_chats.svg";
import activeSession from "../../../assets/img/active_session.svg";
import pendingChat from "../../../assets/img/pending_session.svg";
import chat from "../../../assets/img/chat.svg";
import call from "../../../assets/img/call.svg";
import link from "../../../assets/img/link.svg";

let hash

hash = {
    "hash": localStorage.getItem('user')
        ? localStorage.getItem('user')
        : sessionStorage.getItem('user')
            ? sessionStorage.getItem('user')
            : window.location.hash.slice(1, 33)
}

function ChatRoomsCard(props) {
    const [highlight, setHighlight] = useState(false)
    const [takerName, setTakerName] = useState("...")

    let id = props.data.joinLink,
        time = '',
        oldRoom = !!localStorage.getItem(props.data.ticketNumber),
        activeRoom = localStorage.getItem(props.data.ticketNumber) === "ticketNumber",
        lastMessageArray = []
    id = id.slice(id.indexOf('#room-') + 6)
    id = id.slice(0, id.indexOf('-'))

    props.roomTime.map(element => {
        if (element.id === props.data.ticketNumber) {
            return time = element.time
        }
    })

    lastMessageArray[props.data.ticketNumber] = props.realTimeMessage.filter(item => {
        if (props.data.joinLink.includes(item.roomId)) {
            return item.message || item.managerMessage
        }
    })

    useEffect(() => {
        setHighlight(true)
        setTimeout(() => setHighlight(false), 3000)
    }, [lastMessageArray[props.data.ticketNumber].length])

    const cardTakenHandler = () => {
        props.wss.send(JSON.stringify({
            method: "cardTaken",
            manager: localStorage.getItem('managerName'),
            ticketNumber: props.data.ticketNumber
        }))
        setTakerName(localStorage.getItem('managerName'))
    }

    return (
        <div
            className={`chatRoomsHandler-sessions-session 
            ${highlight ? "highlight" : ""}`}
            key={Math.random()}
            data-ticket={props.data.ticketNumber}
            style={{
                display: activeRoom
                    ? "flex"
                    : oldRoom
                        ? "flex"
                        : "none"
            }}
        >
            <div className="chatRoomsHandler-sessions-session-info">
                <img
                    className="chatRoomsHandler-sessions-session-info-device"
                    src={
                        props.data.screen.slice(0, props.data.screen.indexOf('x')) >= 1024
                            ? desktop
                            : props.data.screen.slice(0, props.data.screen.indexOf('x')) <= 1023 &&
                            props.data.screen.slice(0, props.data.screen.indexOf('x')) >= 768
                            ? tablet
                            : props.data.screen.slice(0, props.data.screen.indexOf('x')) <= 767
                                ? phone
                                : ""
                    }
                    alt="Device"
                />
                <div className="chatRoomsHandler-sessions-session-info-data">
                    <h3 className="chatRoomsHandler-sessions-session-info-data-number">
                        {props.language.session} {props.data.ticketNumber.slice(0, props.data.ticketNumber.lastIndexOf('-') + 1)}
                        <b>{props.data.ticketNumber.slice(props.data.ticketNumber.lastIndexOf('-') + 1)}</b>
                    </h3>
                    <p className="chatRoomsHandler-sessions-session-info-data-date">
                        {
                            props.roomTime.map(time => {
                                return props.data.ticketNumber === time.id
                                    ? time.time
                                    : ""
                            })
                        }
                    </p>
                </div>
                <img
                    className="chatRoomsHandler-sessions-session-info-close"
                    src={close}
                    alt="Close"
                    onClick={props.closeCard}
                />
            </div>
            <a target="_blank" href={props.data.joinLink} className="chatRoomsHandler-sessions-session-link">
                {props.data.joinLink.slice(0, 35) + "..."}
            </a>
            <div className="chatRoomsHandler-sessions-session-status_handler">
                <p className="chatRoomsHandler-sessions-session-status_handler-title">
                    {props.language.chatStatus}
                </p>
                <div className={
                    props.roomEmptyStatus.map(item =>
                        props.data.joinLink.includes(item.roomId)
                    ).includes(true)
                        ? "chatRoomsHandler-sessions-session-info-status_false"
                        : "chatRoomsHandler-sessions-session-info-status_true"
                }/>
            </div>
            <div className="chatRoomsHandler-sessions-session-last_message_block">
                <div className="chatRoomsHandler-sessions-session-last_message_block-handler">
                    <p className="chatRoomsHandler-sessions-session-last_message_block-handler-title">
                        {props.language.theLastChatMessage}
                    </p>
                    <p className="chatRoomsHandler-sessions-session-last_message_block-handler-message">
                        {
                            lastMessageArray[props.data.ticketNumber]
                                .slice(lastMessageArray[props.data.ticketNumber].length - 1)[0] !== undefined
                                ? lastMessageArray[props.data.ticketNumber]
                                    .slice(lastMessageArray[props.data.ticketNumber].length - 1)[0].message ||
                                lastMessageArray[props.data.ticketNumber]
                                    .slice(lastMessageArray[props.data.ticketNumber].length - 1)[0].managerMessage
                                : "..."
                        }
                    </p>
                </div>
                <p className="chatRoomsHandler-sessions-session-last_message_block-counter">
                    {lastMessageArray[props.data.ticketNumber].length}
                </p>
            </div>
            <img
                className="chatRoomsHandler-sessions-session-status"
                src={
                    activeRoom
                        ? activeChat
                        : oldRoom
                        ? pendingChat
                        : activeSession
                }
                alt="Status icon"
            />
            <p className="chatRoomsHandler-sessions-session-managerCard">
                {props.language.cardTakenBy} <b>{
                props.cardTakerName
                    .filter(item => item.ticketNumber === props.data.ticketNumber)[0] !== undefined
                    ? props.cardTakerName
                        .filter(item => item.ticketNumber === props.data.ticketNumber)[0].manager
                    : lastMessageArray[props.data.ticketNumber]
                        .filter(item => item.support >= 1)[0] !== undefined ?
                    lastMessageArray[props.data.ticketNumber]
                        .filter(item => item.support >= 1)[0].from
                    : takerName
            }</b>
            </p>
            <button
                className="chatRoomsHandler-sessions-session-take_card"
                onClick={cardTakenHandler}
            >
                {props.language.takeCard}
            </button>
            <div className="chatRoomsHandler-sessions-session-buttons_handler">
                <button
                    className="chatRoomsHandler-sessions-session-buttons_handler-button"
                    data-id={id}
                    data-ticket={props.data.ticketNumber}
                    onClick={props.handleOpenChatWindow}
                >
                    <span
                        className="chatRoomsHandler-sessions-session-buttons_handler-button-mobile_msg_counter">
                        {lastMessageArray[props.data.ticketNumber].length}
                    </span>
                    <img className="chatRoomsHandler-sessions-session-buttons_handler-button-icon" src={chat}
                         alt="Chat"/>
                    {props.language.chatTitle}
                </button>
                <button className="chatRoomsHandler-sessions-session-buttons_handler-button">
                    <img className="chatRoomsHandler-sessions-session-buttons_handler-button-icon" src={call}
                         alt="Call"/>
                    {props.language.callTitle}
                </button>
                <a
                    className={`chatRoomsHandler-sessions-session-buttons_handler-button
                    ${oldRoom ? 'lastMessage' : 'joinLink'} closeCard`}
                    href={props.data.joinLink}
                    target="_blank"
                    onClick={props.activateInfoCard}
                >
                    <img className="chatRoomsHandler-sessions-session-buttons_handler-button-icon" src={link}
                         alt="Link"/>
                    {props.language.joinTitle}
                </a>
            </div>
        </div>
    )
}

export default ChatRoomsCard