import React, {useEffect, useState} from "react";
import "./MobileMenu.css"
import {slide as Menu} from "react-burger-menu";
import menu from "../../../assets/img/menu.svg"
import back from "../../../assets/img/back.svg"
import Volume from "../../Volume/Volume";
import {Link} from "react-router-dom";

function MobileMenu(props) {
    const [menuStatus, setMenuStatus] = useState(true)
    const [currentLang, getCurrentLang] = useState(navigator.language.split('-')[0].toLowerCase())

    const iconToggle = () => setMenuStatus(!menuStatus)

    const sendLang = event => {
        // window.location.assign(window.location.origin + "/" + event.target.innerHTML.toLowerCase() + window.location.pathname.slice(3))
        props.getLang(event.target.innerHTML.toLowerCase())
        getCurrentLang(event.target.innerHTML.toLowerCase())
    }

    useEffect(() => {
        return document.querySelector('.bm-burger-button').addEventListener('click', iconToggle)
    }, [menuStatus])

    return (
        <Menu
            burgerButtonClassName={`menu_button ${props.visible ? "" : "menu_button-hide"}`}
            customBurgerIcon={<img src={menuStatus ? menu : back} alt="Menu"/>}
        >
            {
                props.currentWidth <= 1024
                    ? <>
                        <Volume
                            volume={props.volume}
                            setVolume={props.setVolume}
                            language={props.language}
                            decreaseVolume={props.decreaseVolume}
                            increaseVolume={props.increaseVolume}
                        />
                        <ul className="header_menu">
                            <Link
                                // to={"/" + props.currentLanguage.toLowerCase() + "/dashboard"}
                                to={"/dashboard"}
                                className="menu-item"
                            >
                                {props.language.dashboard}
                            </Link>
                            {
                                props.infoData.type === 'admin'
                                    ? <>
                                        {/*<Link to={"/" + props.currentLanguage.toLowerCase() + "/statistics"} className="menu-item">*/}
                                        <Link to={"/statistics"} className="menu-item">
                                            {props.language.statistics}
                                        </Link>
                                        {/*<Link to={"/" + props.currentLanguage.toLowerCase() + "/users"} className="menu-item">*/}
                                        <Link to={"/users"} className="menu-item">
                                            {props.language.staff}
                                        </Link>
                                        {/*<Link to={"/" + props.currentLanguage.toLowerCase() + "/settings"} className="menu-item">*/}
                                        <Link to={"/settings"} className="menu-item">
                                            {props.language.settings}
                                        </Link>
                                    </>
                                    : null
                            }
                        </ul>
                        <p className="welcome">
                            {props.language.welcome}
                            <span className="welcome-name">
                            {
                                props.infoData
                                    ? props.infoData.nickname
                                    ? props.infoData.nickname
                                    : !props.infoData.nickname
                                        ? props.infoData.email
                                        : props.infoData.firstName + ' ' + props.infoData.lastName
                                    : null
                            }
                            </span>
                        </p>
                        <div className="header_lang-list mobileLangList">
                            <div
                                className={`header_lang-list-item mobileHeader_lang-list-item ${currentLang === "en" ? "langList-current-true" : ""}`}
                                onClick={sendLang}>EN
                            </div>
                            <div
                                className={`header_lang-list-item mobileHeader_lang-list-item ${currentLang === "ru" ? "langList-current-true" : ""}`}
                                onClick={sendLang}>RU
                            </div>
                            <div
                                className={`header_lang-list-item mobileHeader_lang-list-item ${currentLang === "ua" ? "langList-current-true" : ""}`}
                                onClick={sendLang}>UA
                            </div>
                        </div>
                    </>
                    : <>
                        <ul className="header_menu">
                            {/*<Link to={"/" + props.currentLanguage.toLowerCase() + "/dashboard"} className="menu-item">*/}
                            <Link to={"/dashboard"} className="menu-item">
                                {props.language.dashboard}
                            </Link>
                            {
                                props.infoData.type === 'admin'
                                    ? <>
                                        {/*<Link to={"/" + props.currentLanguage.toLowerCase() + "/statistics"} className="menu-item">*/}
                                        <Link to={"/statistics"} className="menu-item">
                                            {props.language.statistics}
                                        </Link>
                                        {/*<Link to={"/" + props.currentLanguage.toLowerCase() + "/users"} className="menu-item">*/}
                                        <Link to={"/users"} className="menu-item">
                                            {props.language.staff}
                                        </Link>
                                        {/*<Link to={"/" + props.currentLanguage.toLowerCase() + "/settings"} className="menu-item">*/}
                                        <Link to={"/settings"} className="menu-item">
                                            {props.language.settings}
                                        </Link>
                                    </>
                                    : null
                            }
                        </ul>
                    </>
            }
        </Menu>
    )
}

export default MobileMenu