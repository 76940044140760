let lang = navigator.language.split('-')[0].toLowerCase(),
    date = new Date()
date = date.toLocaleTimeString()
export let languagePack = {
    "en": {
        dashboard: "Administration Dashboard",
        statistics: "Statistics",
        staff: "Staff",
        callRequestVolume: "Call request volume:",
        startSession: "Init. DateTime",
        status: "Status",
        location: "Url",
        sessionDuration: "Session duration",
        title: "Request history",
        balance: "Balance:",
        expireDate: "Expire date:",
        getScript: "Get code:",
        copy: "Copy",
        copied: "Copied!",
        codeWasCopied: "Code was copied!",
        pasteCode: "Paste this code into the",
        inFile: "of your main html file.",
        callsToAdminDescription: "Calls from users if they have problems with the tool",
        userCalls: "Room activation:",
        managerCalls: "Manager answers:",
        statisticsTitle: "Statistics for the past 7 days",
        statisticsUpdateTime: `updated ${date}`,
        globalStatisticsTable: "Global Statistics",
        globalStatisticsDescription: "Global statistics includes data about date and domain",
        selectFirstDay: "Please select the first day.",
        selectLastDay: "Please select the last day.",
        selectedFrom: "From",
        selectedTo: "To",
        apply: "Apply",
        reset: "Reset",
        globalStatisticsDate: "Date",
        globalStatisticsRooms: "Rooms",
        globalStatisticsOnline: "Online",
        globalStatisticsCallsManager: "Calls manager",
        domainStatisticsTitle: "Domain Statistics",
        domainStatisticsDescription: "Domain statistics includes data about domain",
        domainStatisticsDomain: "Domain",
        staffTitle: "Staff",
        staffDescription: "Staff that have access to the tool",
        staffId: "Id",
        staffNickName: "Username",
        staffFirstName: "First Name",
        staffLastName: "Last Name",
        staffEmail: "Email",
        staffPassword: "Password",
        staffStatus: "Status",
        staffDate: "Date",
        noRecords: "No records to display",
        message: "Message",
        messageTitle: "Message queue",
        messageDescription: "Messages from users in chat",
        roomNumber: "Room number",
        titleChatHistory: "Chat history",
        activeRoomsTitle: "Active rooms",
        passiveRooms: "No one room is active",
        openChatHistory: "Open chat history",
        joinThisChat: "Join the site",
        chatTitle: "Chat",
        newChats: "New chats",
        pendingChats: "Pending chats",
        activeChats: "Active chats",
        close: "Close",
        unreadMessages: "Unread messages",
        lastMessageInChat: "The last message in chat",
        noMessagesYet: "No messages yet",
        ticketNumber: "Card number",
        authDesktop: "Sign Up / Sign In",
        copointerAuthSignInTitle: "Sign In",
        placeholderSignInEmail: "Email",
        placeholderSignInPassword: "Password",
        rememberMe: "Remember Me",
        copointerAuthSignInForgotPassword: "Forgot Password",
        copointerAuthSignUp: "DON'T HAVE AN ACCOUNT? SIGN UP",
        copointerAuthSignUpTitle: "Sign Up",
        copointerAuthSignIn: "ALREADY HAVE AN ACCOUNT? SIGN IN",
        copointerForgotPasswordButton: "Submit",
        copointerAuthForgotPassword: "Back to Sign In",
        oldSession: "Your session is out of date",
        wrongData: "Wrong email or password",
        loading: "Loading",
        fieldRequired: "This field is required!",
        notValidEmail: "This is not a valid email.",
        notValidPassword: "The password must be between 6 and 40 characters.",
        accountCreated: "Thanks! Your account has been successfully created.",
        checkYourInbox: "Please check your inbox, activation link is sent on your email. If you see it, click on it and wait while we activating your account.",
        deleteMessage: "Delete",
        saveMessage: "Save",
        editMessage: "Edit",
        offline: "Offline",
        online: "Online",
        logout: "Logout",
        welcome: "Welcome:",
        roomsAndChats: "Rooms and chats",
        allSessions: "All sessions",
        search: "Search",
        settings: "Settings",
        workStart: "Work start:",
        workEnd: "Work end:",
        monday: "Monday:",
        tuesday: "Tuesday:",
        wednesday: "Wednesday:",
        thursday: "Thursday:",
        friday: "Friday:",
        saturday: "Saturday:",
        sunday: "Sunday:",
        closeStatus: "Closed:",
        languageTitle: "Language",
        managersMessagesSettings: "Managers messages settings",
        sitesMessagesOfflineSettings: "Sites messages(offline) settings",
        sitesMessagesOnlineSettings: "Sites messages(online) settings",
        question: "Question",
        answer: "Answer",
        answersForQuestions: "Answers on questions",
        cardTakenBy: "The card was taken by the",
        session: "Session:",
        chatStatus: "Chat status:",
        theLastChatMessage: "The last message in chat:",
        takeCard: "Take card",
        callTitle: "Call",
        joinTitle: "Join",
    },
    "ru": {
        dashboard: "Панель администратора",
        statistics: "Статистика",
        staff: "Сотрудники",
        callRequestVolume: "Громкость вызова менеджера:",
        startSession: "Дата",
        status: "Статус",
        location: "Ссылка",
        sessionDuration: "Продолжительность сессии",
        title: "История запросов",
        balance: "Счёт:",
        expireDate: "Дата истечения:",
        getScript: "Получить код:",
        copy: "Скопировать",
        copied: "Скопировано!",
        codeWasCopied: "Код был скопирован!",
        pasteCode: "Вставьте этот код в",
        inFile: "вашего главного html файла.",
        callsToAdminDescription: "Звонки от пользователей, если у них проблемы с инструментом",
        userCalls: "Активация комнат:",
        managerCalls: "Ответы менеджера:",
        statisticsTitle: "Статистика за последние 7 дней",
        statisticsUpdateTime: `обновлено ${date}`,
        globalStatisticsTable: "Общая статистика",
        globalStatisticsDescription: "Общая статистика включает данные о дате и домене",
        selectFirstDay: "Пожалуйста, выберите первый день.",
        selectLastDay: "Пожалуйста, выберите последний день.",
        selectedFrom: "От",
        selectedTo: "До",
        apply: "Применить",
        reset: "Сбросить",
        globalStatisticsDate: "Дата",
        globalStatisticsRooms: "Комнаты",
        globalStatisticsOnline: "В сети",
        globalStatisticsCallsManager: "Вызов менеджера",
        domainStatisticsTitle: "Статистика домена",
        domainStatisticsDescription: "Статистика домена включает данные о домене",
        domainStatisticsDomain: "Домен",
        staffTitle: "Сотрудники",
        staffDescription: "Сотрудники, имеющие доступ к инструменту",
        staffId: "Идентификатор",
        staffNickName: "Пользовательское имя",
        staffFirstName: "Имя",
        staffLastName: "Фамилия",
        staffEmail: "Электронная почта",
        staffPassword: "Пароль",
        staffStatus: "Статус",
        staffDate: "Дата",
        noRecords: "Нет записей для отображения",
        message: "Сообщение",
        messageTitle: "Очередь сообщений",
        messageDescription: "Сообщения от пользователей в чате",
        roomNumber: "Номер комнаты",
        titleChatHistory: "История чата",
        activeRoomsTitle: "Активные комнаты",
        passiveRooms: "Ни одна комната не активна",
        openChatHistory: "Открыть историю чата",
        joinThisChat: "Присоединиться на сайте",
        chatTitle: "Чат",
        newChats: "Новые чаты",
        pendingChats: "Чаты в ожидании",
        activeChats: "Активные чаты",
        close: "Закрыть",
        unreadMessages: "Непрочитанные сообщения",
        lastMessageInChat: "Последнее сообщение в чате",
        noMessagesYet: "Пока что сообщений нету",
        ticketNumber: "Номер сессии",
        authDesktop: "Зарегистрироваться / Войти",
        copointerAuthSignInTitle: "Войти",
        placeholderSignInEmail: "Почта",
        placeholderSignInPassword: "Пароль",
        rememberMe: "Запомнить меня",
        copointerAuthSignInButton: "Войти",
        copointerAuthSignInForgotPassword: "Забыли пароль?",
        copointerAuthSignUp: "НЕТ УЧЕТНОЙ ЗАПИСИ? РЕГЕСТРИРУЙТЕСЬ",
        copointerAuthSignUpTitle: "Зарегистрироваться",
        copointerAuthSignUpButton: "Зарегистрироваться",
        copointerAuthSignIn: "УЖЕ ЕСТЬ АККАУНТ? ВОЙТИ",
        copointerForgotPasswordButton: "Подтвердить",
        copointerAuthForgotPassword: "Вернуться к входу",
        oldSession: "Ваша сессия устарела",
        wrongData: "Неправильная почта или пароль",
        loading: "Загрузка",
        fieldRequired: "Это поле обязательно к заполнению!",
        notValidEmail: "Это недействительный адрес электронной почты.",
        notValidPassword: "Пароль должен содержать от 6 до 40 символов.",
        accountCreated: "Спасибо! Ваша учетная запись была успешно создана.",
        checkYourInbox: "Пожалуйста, проверьте свой почтовый ящик, ссылка для активации будет отправлена на вашу электронную почту. Если вы его видите, нажмите на него и подождите, пока мы активируем вашу учетную запись.",
        deleteMessage: "Удалить",
        saveMessage: "Сохранить",
        editMessage: "Редактировать",
        offline: "Не в сети",
        online: "В сети",
        logout: "Выход",
        welcome: "Добро пожаловать:",
        roomsAndChats: "Комнаты и чаты",
        allSessions: "Все сессии",
        search: "Поиск",
        settings: "Настройки",
        workStart: "Начало работы:",
        workEnd: "Конец работы:",
        monday: "Понедельник:",
        tuesday: "Вторник:",
        wednesday: "Среда:",
        thursday: "Четверг:",
        friday: "Пятница:",
        saturday: "Суббота:",
        sunday: "Воскресенье:",
        closeStatus: "Закрыто:",
        languageTitle: "Язык",
        managersMessagesSettings: "Настройка сообщений от менеджера",
        sitesMessagesOfflineSettings: "Настройка сообщений(офлайн) от сайта",
        sitesMessagesOnlineSettings: "Настройка сообщений(онлайн) от сайта",
        question: "Вопрос",
        answer: "Ответ",
        answersForQuestions: "Ответы на вопросы",
        cardTakenBy: "Карточка была взята",
        session: "Сессия:",
        chatStatus: "Статус чата:",
        theLastChatMessage: "Последнее сообщение в чате:",
        takeCard: "Взять карточку",
        callTitle: "Звонок",
        joinTitle: "Перейти",
    },
    "ua": {
        dashboard: "Панель адміністратора",
        statistics: "Статистика",
        staff: "Співробітники",
        callRequestVolume: "Гучність виклику менеджера:",
        startSession: "Дата",
        status: "Статус",
        location: "Посилання",
        sessionDuration: "Тривалість сесії",
        title: "Iсторiя запитів",
        balance: "Дата завершення:",
        getScript: "Отримати код:",
        copy: "Скопіювати",
        copied: "Скопійовано!",
        codeWasCopied: "Код був скопійований!",
        pasteCode: "Вставте цей код в",
        inFile: "вашого головного html файлу.",
        callsToAdminDescription: "Дзвінки від користувачів, якщо вони мають проблеми з інструментом",
        userCalls: "Активація кімнат:",
        managerCalls: "Відповіді менеджера:",
        statisticsTitle: "Статистика за останні 7 днів",
        statisticsUpdateTime: `оновлено ${date}`,
        globalStatisticsTable: "Загальна статистика",
        globalStatisticsDescription: "Загальна статистика включає дані про дату та домен",
        selectFirstDay: "Будь ласка, виберіть перший день.",
        selectLastDay: "Будь ласка, виберіть останній день.",
        selectedFrom: "Від",
        selectedTo: "До",
        apply: "Застосувати",
        reset: "Скинути",
        globalStatisticsDate: "Дата",
        globalStatisticsRooms: "Кімнати",
        globalStatisticsOnline: "В мережі",
        globalStatisticsCallsManager: "Виклик менеджера",
        domainStatisticsTitle: "Статистика домену",
        domainStatisticsDescription: "Статистика домену включає дані про домен",
        domainStatisticsDomain: "Домен",
        staffTitle: "Співробітники",
        staffDescription: "Співробітники, які мають доступ до інструменту",
        staffId: "Ідентифікатор",
        staffNickName: "Користувацьке iм'я",
        staffFirstName: "Ім'я",
        staffLastName: "Прізвище",
        staffEmail: "Електронна пошта",
        staffPassword: "Пароль",
        staffStatus: "Статус",
        staffDate: "Дата",
        noRecords: "Немає записів для відображення",
        message: "Повідомлення",
        messageTitle: "Черга повідомлень",
        messageDescription: "Повідомлення від користувачів в чаті",
        roomNumber: "Номер кiмнати",
        titleChatHistory: "Iсторiя чату",
        activeRoomsTitle: "Активнi кiмнати",
        passiveRooms: "Жодна кімната не активна",
        openChatHistory: "Відкрити історію чату",
        joinThisChat: "Приєднатися на сайті",
        chatTitle: "Чат",
        newChats: "Нові чати",
        pendingChats: "Чати в очікуванні",
        activeChats: "Активні чати",
        close: "Закрити",
        unreadMessages: "Непрочитані повідомлення",
        lastMessageInChat: "Останнє повідомлення в чаті",
        noMessagesYet: "Поки що повідомлень немає",
        ticketNumber: "Номер сесії",
        authDesktop: "Зареєструватися / Увійти",
        copointerAuthSignInTitle: "Увійти",
        placeholderSignInEmail: "Пошта",
        placeholderSignInPassword: "Пароль",
        rememberMe: "Запам'ятати мене",
        copointerAuthSignInButton: "Увійти",
        copointerAuthSignInForgotPassword: "Забули пароль?",
        copointerAuthSignUp: "НЕ МАЄТЕ АККАУНТА? РЕЄСТРУЙТЕСЯ",
        copointerAuthSignUpTitle: "Зареєструватися",
        copointerAuthSignUpButton: "Зареєструватися",
        copointerAuthSignIn: "ВЖЕ Є АККАУНТ? УВІЙТИ",
        copointerForgotPasswordButton: "Підтвердити",
        copointerAuthForgotPassword: "Повернутися до входу",
        oldSession: "Ваша сесія застаріла",
        wrongData: "Неправильна пошта або пароль",
        loading: "Завантаження",
        fieldRequired: "Це поле є обов`язковим для заповнення!",
        notValidEmail: "Це недійсний адресу електронної пошти.",
        notValidPassword: "Пароль повинен містити від 6 до 40 символів.",
        accountCreated: "Спасибі! Ваш обліковий запис була успішно створена.",
        checkYourInbox: "Будь ласка, перевірте свою поштову скриньку, посилання для активації буде відправлена на вашу електронну пошту. Якщо ви його бачите, натисніть на нього і почекайте, поки ми активуємо ваш обліковий запис.",
        deleteMessage: "Видалити",
        saveMessage: "Зберегти",
        editMessage: "Редагувати",
        offline: "Не в мережі",
        online: "В мережі",
        logout: "Вихід",
        welcome: "Ласкаво просимо:",
        roomsAndChats: "Кімнати та чати",
        allSessions: "Всі сесії",
        search: "Пошук",
        settings: "Налаштування",
        workStart: "Початок роботи:",
        workEnd: "Кінець роботи:",
        monday: "Понеділок:",
        tuesday: "Вівторок:",
        wednesday: "Середа:",
        thursday: "Четвер:",
        friday: "П'ятниця:",
        saturday: "Субота:",
        sunday: "Неділя:",
        closeStatus: "Зачинено:",
        languageTitle: "Мова",
        managersMessagesSettings: "Налаштування повідомлень від менеджера",
        sitesMessagesOfflineSettings: "Налаштування повідомлень(офлайн) від сайту",
        sitesMessagesOnlineSettings: "Налаштування повідомлень(онлайн) від сайту",
        question: "Питання",
        answer: "Відповідь",
        answersForQuestions: "Відповіді на запитання",
        cardTakenBy: "Карточка була взята",
        session: "Сесія:",
        chatStatus: "Статус чату:",
        theLastChatMessage: "Останнє повідомлення в чаті:",
        takeCard: "Взяти картку",
        callTitle: "Дзвінок",
        joinTitle: "Перейти",
    },
}

export let lpack = (languagePack[lang] ? languagePack[lang] : languagePack['en']);