import React from "react";
import "./ChatRoomsCategories.css"
import activeChats from "../../../assets/img/active_chats.svg"
import activeSessions from "../../../assets/img/active_session.svg"
import pendingSessions from "../../../assets/img/pending_session.svg"

function ChatRoomsCategories(props) {
    return (
        <div className="chatRoomsCategories">
            <button
                data-id="allSessions"
                className={`chatRoomsCategories-button 
                ${props.currentCategory === "allSessions" ? "activeCategory" : ""}`}
                onClick={props.getCategory}
            >
                {props.language.allSessions}
            </button>
            {/*<button*/}
            {/*    data-id="activeSessions"*/}
            {/*    className={`chatRoomsCategories-button */}
            {/*    ${props.currentCategory === "activeSessions" ? "activeCategory" : ""}`}*/}
            {/*    onClick={props.getCategory}*/}
            {/*>*/}
            {/*    <img*/}
            {/*        className="chatRoomsCategories-button-icon"*/}
            {/*        src={activeSessions}*/}
            {/*        alt="Active sessions"*/}
            {/*    />*/}
            {/*    {props.language.activeRoomsTitle} {*/}
            {/*    props.activeSessions*/}
            {/*        ? `(${props.activeSessions.length})`*/}
            {/*        : ""*/}
            {/*}*/}
            {/*</button>*/}
            <button
                data-id="pendingChats"
                className={`chatRoomsCategories-button 
                ${props.currentCategory === "pendingChats" ? "activeCategory" : ""}`}
                onClick={props.getCategory}
            >
                <img
                    className="chatRoomsCategories-button-icon"
                    src={pendingSessions}
                    alt="Pending sessions"
                />
                {/*{props.language.pendingChats} {props.pendingChats ? `(${*/}
                {/*props.pendingChats.filter(item => item !== null).length*/}
                {/*})` : ""}*/}
                {props.language.pendingChats} {`(${props.pendingChats
                .map(pendingChat =>
                    props.wssData.map(item =>
                        pendingChat === item.ticketNumber
                    )).length
            })`}
            </button>
            <button
                data-id="activeChats"
                className={`chatRoomsCategories-button 
                ${props.currentCategory === "activeChats" ? "activeCategory" : ""}`}
                onClick={props.getCategory}
            >
                <img
                    className="chatRoomsCategories-button-icon"
                    src={activeChats}
                    alt="Active chats"
                />
                {props.language.activeChats} {`(${props.supportedChats
                .map(supportedChat =>
                    props.wssData.map(item =>
                        supportedChat === item.ticketNumber
                    )).length
            })`}
                {/*{props.supportedChats ? `(${props.supportedChats.length})` : ""}*/}
            </button>
        </div>
    )
}

export default ChatRoomsCategories