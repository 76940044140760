import React, {Component} from "react";
import axios from "axios";
import MaterialTable from "material-table";
import styled from "styled-components"
import "./answersForQuestions.css"

const AnswersForQuestionsTableHandler = styled.div`
    z-index: 0;
    width: 100%;
    margin-top: 8%;
`

export default class AnswersForQuestionsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {title: this.props.language.staffId, field: 'id', editable: 'never', grouping: false},
                {
                    title: this.props.language.domainStatisticsDomain,
                    field: 'domain',
                    grouping: false
                },
                {
                    title: this.props.language.question,
                    field: 'question',
                    defaultGroupOrder: 2
                },
                {
                    title: this.props.language.question,
                    field: 'question',
                    validate: rowData => rowData.message === '' ? 'Question cannot be empty' : '',
                    grouping: false
                },
                {
                    title: this.props.language.answer,
                    field: 'answer',
                    validate: rowData => rowData.message === '' ? 'Answer cannot be empty' : '',
                    grouping: false
                },
            ],
            data: [],
            hash: localStorage.getItem('user') ? localStorage.getItem('user') : localStorage.getItem('userNotRemember')
        }
    }

    componentDidMount() {
        let options = this.props.infoData.option
        options = options && JSON.parse(options)
        this.setState({
            data: options ? options.answersForQuestions : [],
        })
    }

    addData = (newData, url) => {
        let hash = {
            "hash": this.state.hash
        }
        let dataMsg = Object.assign({}, newData)
        dataMsg.id !== 0 || dataMsg.id
            ? dataMsg.id = Math.random().toString().slice(2)
            : dataMsg.id = 0
        this.props.addAnswerForQuestion(dataMsg)
        return axios.post(url, {
            hash: hash.hash, method: "UPDATE", option: this.props.option
        }).then(() => {
            this.props.updateData()
        })
    }

    updateData = (newData, url) => {
        let hash = {
            "hash": this.state.hash
        }
        this.props.updateAnswerForQuestion(newData)
        return axios.post(url, {
            hash: hash.hash, method: "UPDATE", option: this.props.option
        }).then(() => {
            this.props.updateData()
        })
    }

    deleteData = (oldData, url) => {
        let hash = {
            "hash": this.state.hash
        }
        if (oldData.question !== "default") {
            this.props.deleteAnswerForQuestion(oldData)
        }
        return axios.post(url, {
            hash: hash.hash, method: "UPDATE", option: this.props.option
        }).then(() => {
            this.props.updateData()
        })
    }

    render() {
        return (
            <AnswersForQuestionsTableHandler>
                <MaterialTable
                    title={this.props.language.answersForQuestions}
                    columns={this.state.columns}
                    data={this.state.data}
                    options={{
                        grouping: true,
                    }}
                    editable={{
                        onRowAdd: newData => {
                            return this.addData(newData, 'https://copointer.com:7777/info')
                        },
                        onRowUpdate: newData => {
                            return this.updateData(newData, 'https://copointer.com:7777/info')
                        },
                        onRowDelete: oldData => {
                            return this.deleteData(oldData, 'https://copointer.com:7777/info')
                        }
                    }}
                />
            </AnswersForQuestionsTableHandler>
        );
    }
}