import React, {useEffect, useState} from "react";
import "./ChatRoomsHandler.css"
import ChatRoomsCategories from "./ChatRoomsCategories/ChatRoomsCategories";
import ChatRoomsCard from "./ChatRoomsCard/ChatRoomsCard";
import ChatRoomsMobileCategories from "./ChatRoomsMobileCategories/ChatRoomsMobileCategories";

function ChatRoomsHandler(props) {
    const [currentCategory, setCategory] = useState('allSessions')
    const wssData = [...new Set(props.wssData)]
    const activeSessions = [...new Set(props.activeSessions)]
    const pendingChats = [...new Set(props.pendingChats)]
    const supportedChats = [...new Set(props.supportedChats)]

    const getCategory = category => {
        setCategory(category.target.getAttribute('data-id'))
    }

    useEffect(() => {
        props.categoryHandler(currentCategory)
    }, [currentCategory])

    useEffect(() => {
        if (props.activeChatStatus) {
            setCategory("activeChats")
        }
        setTimeout(() => props.setActiveChatStatus(false), 0)
    }, [props.activeChatStatus])

    return (
        <div className="chatRoomsHandler">
            <h2 className="chatRoomsHandler-title">
                {props.language.roomsAndChats}
            </h2>
            <ChatRoomsCategories
                getCategory={getCategory}
                currentCategory={currentCategory}
                wssData={props.wssData}
                language={props.language}
                pendingChats={props.pendingChats}
                supportedChats={props.supportedChats}
                activeSessions={props.activeSessions}
            />
            <div className="chatRoomsHandler-sessions">
                {currentCategory === "allSessions" && wssData
                    .map(item =>
                        <ChatRoomsCard
                            data={item}
                            language={props.language}
                            cardTakerName={props.cardTakerName}
                            realTimeMessage={props.wssDataMessage}
                            roomEmptyStatus={props.roomEmptyStatus}
                            roomTime={props.roomTime}
                            closeCard={props.closeCard}
                            activateInfoCard={props.activateInfoCard}
                            openChatWindow={props.openChatWindow}
                            wss={props.wss}
                            joinLink={props.joinLink}
                            activeRoomId={props.activeRoomId}
                            handleCloseChatWindow={props.handleCloseChatWindow}
                            handleOpenChatWindow={props.handleOpenChatWindow}
                        />
                    )}
                {currentCategory === "activeSessions" && activeSessions
                    .map(activeSession => {
                        return wssData.map(item =>
                            activeSession === item.ticketNumber &&
                            <ChatRoomsCard
                                data={item}
                                language={props.language}
                                cardTakerName={props.cardTakerName}
                                realTimeMessage={props.wssDataMessage}
                                roomEmptyStatus={props.roomEmptyStatus}
                                roomTime={props.roomTime}
                                closeCard={props.closeCard}
                                activateInfoCard={props.activateInfoCard}
                                openChatWindow={props.openChatWindow}
                                wss={props.wss}
                                joinLink={props.joinLink}
                                activeRoomId={props.activeRoomId}
                                handleCloseChatWindow={props.handleCloseChatWindow}
                                handleOpenChatWindow={props.handleOpenChatWindow}
                            />
                        )
                    })}
                {currentCategory === "pendingChats" && pendingChats
                    .map(pendingChat =>
                        wssData.map(item =>
                            pendingChat === item.ticketNumber &&
                            <ChatRoomsCard
                                data={item}
                                language={props.language}
                                cardTakerName={props.cardTakerName}
                                realTimeMessage={props.wssDataMessage}
                                roomEmptyStatus={props.roomEmptyStatus}
                                roomTime={props.roomTime}
                                closeCard={props.closeCard}
                                activateInfoCard={props.activateInfoCard}
                                openChatWindow={props.openChatWindow}
                                wss={props.wss}
                                joinLink={props.joinLink}
                                activeRoomId={props.activeRoomId}
                                handleCloseChatWindow={props.handleCloseChatWindow}
                                handleOpenChatWindow={props.handleOpenChatWindow}
                            />
                        )
                    )}
                {currentCategory === "activeChats" && supportedChats
                    .map(supportedChat =>
                        wssData.map(item =>
                            supportedChat === item.ticketNumber &&
                            <ChatRoomsCard
                                data={item}
                                language={props.language}
                                cardTakerName={props.cardTakerName}
                                realTimeMessage={props.wssDataMessage}
                                roomEmptyStatus={props.roomEmptyStatus}
                                roomTime={props.roomTime}
                                closeCard={props.closeCard}
                                activateInfoCard={props.activateInfoCard}
                                openChatWindow={props.openChatWindow}
                                wss={props.wss}
                                joinLink={props.joinLink}
                                activeRoomId={props.activeRoomId}
                                handleCloseChatWindow={props.handleCloseChatWindow}
                                handleOpenChatWindow={props.handleOpenChatWindow}
                            />
                        )
                    )}
                <ChatRoomsMobileCategories
                    getCategory={getCategory}
                    currentCategory={currentCategory}
                    wssData={props.wssData}
                    language={props.language}
                    pendingChats={props.pendingChats}
                    supportedChats={props.supportedChats}
                />
            </div>
        </div>
    )
}

export default ChatRoomsHandler