import React, {Component} from "react";
import axios from "axios";

const API_URL_AUTH = "https://copointer.com:7777/auth/";
const API_URL_REG = "https://copointer.com:7777/registration/";
const API_URL_ACT = "https://copointer.com:7777/activation/";

class AuthService extends Component {
    login = (email, password) => {
        return axios
            .post(API_URL_AUTH, {
                email,
                password
            })
    }

    register = (email, password) => {
        return axios.post(API_URL_REG, {
            email,
            password
        })
    }

    activation = (token) => {
        if (!token) {
            return null
        } else {
            return axios.post(API_URL_ACT, {
                token
            }).then(
                response => {
                    if (response.data.hash) {
                        window.location.assign(
                            `${window.location.origin}/en/dashboard/#${response.data.hash}?false`
                        )
                    } else if (response.data.error === 'WRONGACC') {
                        window.location.assign(
                            `${window.location.origin}/auth?no_data`
                        )
                    }
                }
            )
        }
    }

    getCurrentUser = () => {
        return JSON.parse(localStorage.getItem('user'));
    }
}

export default new AuthService();