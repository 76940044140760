import React, {useEffect, useState} from "react";
import Header from "../components/Header/Header";
import Volume from "../components/Volume/Volume";
import ChatRoomsHandler from "../components/ChatRoomsHandler/ChatRoomsHandler";
import RequestsHistory from "../components/RequestsHistory/RequestsHistory";
import Footer from "../components/Footer/Footer";
import axios from "axios"
import remove from "../components/StorageClean/remove";
import useSound from "use-sound";
import room from "../assets/audio/roomSound.mp3"
import message from "../assets/audio/message.mp3"
import styled from "styled-components"
import ReloadModal from "../components/ReloadModalWarning/ReloadModal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Chat from "../components/Chat/Chat";
import Modal from "@material-ui/core/Modal";

let hash

if (window.location.hash.includes('?true')) {
    localStorage.setItem('user', window.location.hash.slice(1, 33))
} else {
    if (localStorage.getItem('userNotRemember') !== "") {
        localStorage.setItem('userNotRemember', window.location.hash.slice(1, 33))
    }
}

hash = {
    "hash": localStorage.getItem('user')
        ? localStorage.getItem('user')
        : localStorage.getItem('userNotRemember')
            ? localStorage.getItem('userNotRemember')
            : window.location.hash.slice(1, 33)
}

const DashboardHandler = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

function Dashboard(props) {
    let _isMounted = false
    const [wssStatus, setWssStatus] = useState(
        localStorage.getItem('connectStatus')
            ? localStorage.getItem('connectStatus') === 'true'
            : true
    );

    const connectWss = connectStatus => {
        setWssStatus(connectStatus)
        localStorage.setItem('connectStatus', connectStatus)
    }

    let managerSendStatus = true

    const reverseString = str => {
        return str.split("").reverse().join("");
    }

    let first = new Date(),
        second = new Date(),
        third = new Date(),
        fourth = new Date(),
        fifth = new Date(),
        six = new Date(),
        seven = new Date();
    first.setDate(first.getDate() - 6)
    second.setDate(second.getDate() - 5)
    third.setDate(third.getDate() - 4)
    fourth.setDate(fourth.getDate() - 3)
    fifth.setDate(fifth.getDate() - 2)
    six.setDate(six.getDate() - 1)
    seven.setDate(seven.getDate())

    let [firstUsersPoint, getDataOfUsersForFirst] = useState(0);
    let [secondUsersPoint, getDataOfUsersForSecond] = useState(0);
    let [thirdUsersPoint, getDataOfUsersForThird] = useState(0);
    let [fourthUsersPoint, getDataOfUsersForFourth] = useState(0);
    let [fifthUsersPoint, getDataOfUsersForFifth] = useState(0);
    let [sixUsersPoint, getDataOfUsersForSix] = useState(0);
    let [sevenUsersPoint, getDataOfUsersForSeven] = useState(0);
    let [firstAdminsPoint, getDataOfAdminsForFirst] = useState(0);
    let [secondAdminsPoint, getDataOfAdminsForSecond] = useState(0);
    let [thirdAdminsPoint, getDataOfAdminsForThird] = useState(0);
    let [fourthAdminsPoint, getDataOfAdminsForFourth] = useState(0);
    let [fifthAdminsPoint, getDataOfAdminsForFifth] = useState(0);
    let [sixAdminsPoint, getDataOfAdminsForSix] = useState(0);
    let [sevenAdminsPoint, getDataOfAdminsForSeven] = useState(0);
    const [wss, setWss] = React.useState(
        window.location.hash
            ? reverseString(window.location.hash.slice(34, 38) === 'true' ? window.location.hash.slice(39) : window.location.hash.slice(40))
            : localStorage.getItem('wss')
    );
    localStorage.setItem('wss', wss !== null ? wss.toString() : "")
    const [websocket, getWebSocket] = useState(undefined)
    const [activeRoomId, setActiveRoomId] = useState('');
    const [joinLink, setJoinLink] = useState('');
    const [wssData, setWebSocketData] = useState([]);
    const [roomTime, setTimeForRoom] = useState([]);
    const [supportedChats, setSupportedChats] = useState([]);
    const [pendingChats, setPendingChats] = useState([]);
    const [activeCategory, categoryHandler] = useState("");
    const [wssDataClosed, setWebSocketDataClosed] = useState([]);
    const [sessionStatus, setStatusForSession] = useState(true);
    const [manualSessionStatus, setStatusForSessionManual] = useState(true);
    const [wssDataMessage, setWebSocketDataMessage] = useState([]);
    const [messageForNotification, setMessageForNotification] = useState('Message does not exist');
    const [roomForNotification, setRoomForNotification] = useState('Number does not exist');
    const [newMessage, setStatusForNewMessage] = useState(false);
    const [wssErrorData, setWebSocketErrorData] = useState([]);
    const [openChatWindow, setOpenChatWindow] = useState(false);
    const [volume, getVolume] = useState(localStorage.getItem('volume') ? localStorage.getItem('volume') : 1)
    const [currentWidth, getCurrentWidth] = useState(window.innerWidth)
    const [infoData, getInfoData] = useState(props)
    const [activeChatStatus, setActiveChatStatus] = useState(false)
    const [activeSessions, setActiveSessions] = useState([])
    const [reloadStatus, setReloadStatus] = useState(false)
    const [editedMessages, getEditedMessages] = useState([])
    const [deletedMessages, getDeletedMessages] = useState([])
    const [typingUser, getTypingUser] = useState([])
    const [roomEmptyStatus, getRoomEmptyStatus] = useState([])
    const [cardTakerName, getCardTakerName] = useState([])

    useEffect(() => {
        getInfoData(props)
    }, [props])

    const getData = () => {
        axios.post('https://copointer.com:7777/stats', hash)
            .then(response => {
                if (typeof response.data !== "string") {
                    getDataOfAdminsForFirst(
                        response.data
                            .filter(item => item.date === first.toISOString().substr(0, 10)
                                ? eval(item.managerCalls + item.coBrowsingCount)
                                : 0
                            )
                    )
                    getDataOfAdminsForSecond(
                        response.data
                            .filter(item => item.date === second.toISOString().substr(0, 10)
                                ? eval(item.managerCalls + item.coBrowsingCount)
                                : 0
                            )
                    )
                    getDataOfAdminsForThird(
                        response.data
                            .filter(item => item.date === third.toISOString().substr(0, 10)
                                ? eval(item.managerCalls + item.coBrowsingCount)
                                : 0
                            )
                    )
                    getDataOfAdminsForFourth(
                        response.data
                            .filter(item => item.date === fourth.toISOString().substr(0, 10)
                                ? eval(item.managerCalls + item.coBrowsingCount)
                                : 0
                            )
                    )
                    getDataOfAdminsForFifth(
                        response.data
                            .filter(item => item.date === fifth.toISOString().substr(0, 10)
                                ? eval(item.managerCalls + item.coBrowsingCount)
                                : 0
                            )
                    )
                    getDataOfAdminsForSix(
                        response.data
                            .filter(item => item.date === six.toISOString().substr(0, 10)
                                ? eval(item.managerCalls + item.coBrowsingCount)
                                : 0
                            )
                    )
                    getDataOfAdminsForSeven(
                        response.data
                            .filter(item => item.date === seven.toISOString().substr(0, 10)
                                ? eval(item.managerCalls + item.coBrowsingCount)
                                : 0
                            )
                    )

                    if (response.data.error === 'WRONGHASH') {
                        remove()
                    }
                }
            })
    }

    useEffect(() => {
        _isMounted = true
        getData()
        window.addEventListener("resize", event => {
            getCurrentWidth(event.target.screen.width)
        })
        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
        } else {
            Notification.requestPermission();
        }
        return () => {
            _isMounted = false
        }
    }, [])

    const [playMessageSound] = useSound(message, {volume: volume / 10})

    const updateCardDataMessage = msg => {
        document.querySelectorAll('.joinLink').forEach(element => {
            if (element.getAttribute('href') !== null) {
                if (element.getAttribute('href').includes(msg.roomId)) {
                    if (localStorage.getItem(element.parentElement.firstChild.getAttribute('data-ticket')) !== "pendingChats") {
                        localStorage.setItem(element.parentElement.firstChild.getAttribute('data-ticket'), "pendingChats")
                    }
                    if (
                        !pendingChats.includes(element.parentElement.firstChild.getAttribute('data-ticket')) &&
                        !supportedChats.includes(element.parentElement.firstChild.getAttribute('data-ticket'))
                    ) {
                        setPendingChats(prevState => [
                            ...prevState, element.parentElement.firstChild.getAttribute('data-ticket')
                        ])
                        setActiveSessions(
                            activeSessions.filter(activeSession => {
                                return activeSession === element.parentElement.firstChild.getAttribute('data-ticket')
                                    ? null
                                    : activeSession
                            })
                        )
                    }
                }
            }
        })
        setStatusForNewMessage(false)
    }

    const deleteCard = msg => {
        document.querySelectorAll('.closeCard').forEach(element => {
            if (element.getAttribute('href').includes(msg.roomId)) {
                localStorage.removeItem(element.parentElement.parentElement.getAttribute('data-ticket'))
                setWebSocketData(wssData.filter(item => {
                    return item.ticketNumber === element.parentElement.parentElement.getAttribute('data-ticket')
                        ? null
                        : item
                }))
                setActiveSessions(activeSessions.filter(item => {
                    return item === element.parentElement.parentElement.getAttribute('data-ticket')
                        ? null
                        : item
                }))
                setPendingChats(pendingChats.filter(item => {
                    return item === element.parentElement.parentElement.getAttribute('data-ticket')
                        ? null
                        : item
                }))
                setSupportedChats(supportedChats.filter(item => {
                    return item === element.parentElement.parentElement.getAttribute('data-ticket')
                        ? null
                        : item
                }))
                setStatusForSession(true)
            }
        })
    }

    const closeCard = event => {
        localStorage.removeItem(event.target.parentElement.parentElement.getAttribute('data-ticket'))
        setWebSocketData(wssData.filter(item => {
            return item.ticketNumber === event.target.parentElement.parentElement.getAttribute('data-ticket')
                ? null
                : item
        }))
        setStatusForSessionManual(false)
    }

    const connectToWebSocket = () => {
        const webSocket = new WebSocket(`wss://${wss}`)
        if (webSocket.readyState !== 0 || webSocket.readyState !== 1) {
            getWebSocket(webSocket)
            webSocket.onopen = () => {
                webSocket.send(JSON.stringify({method: "manager", hash: hash.hash}))
            }
        }

        webSocket.onmessage = evt => {
            if (managerSendStatus) {
                // webSocket.send(JSON.stringify({method: "manager"}))
                managerSendStatus = false
            }
            const message = JSON.parse(evt.data)

            switch (message.method) {
                case "error":
                    setWebSocketErrorData(message)
                    break;
                case "roomCreated":
                    setWebSocketData((prevState) => {
                        return [...prevState, message]
                    })
                    setTimeForRoom((prevState) => {
                        return [...prevState, {
                            id: message.ticketNumber,
                            time: new Date().toLocaleString()
                        }]
                    })
                    setRoomForNotification(message)
                    setStatusForSession(true)
                    setStatusForSessionManual(true)
                    break;
                case "roomClosed":
                    setWebSocketDataClosed(message)
                    setStatusForSession(false)
                    break;
                case "chatMessage":
                    setWebSocketDataMessage((prevState) => {
                        return [...prevState, message]
                    })
                    setMessageForNotification(message)
                    setStatusForNewMessage(true)
                    break;
                case "reload":
                    setReloadStatus(true)
                    break;
                case "editChatMessage":
                    getEditedMessages(prevState => [...prevState, message])
                    break;
                case "deleteChatMessage":
                    getDeletedMessages(prevState => [...prevState, message.messageId])
                    break;
                case "typingBegin":
                    getTypingUser(prevState => [...prevState, message])
                    break;
                case "roomEmpty":
                    getRoomEmptyStatus(prevState => [...prevState, message])
                    break;
                case "cardTaken":
                    getCardTakerName(prevState => [...prevState, message])
                    break;
            }
        }

        webSocket.onclose = () => {
            if (localStorage.getItem('connectStatus') === 'true' && webSocket.readyState === 3) {
                setTimeout(() => {
                    managerSendStatus = true
                    connectToWebSocket();
                    setWebSocketData([])
                    setTimeForRoom([])
                    setActiveSessions([])
                    setPendingChats([])
                    setSupportedChats([])
                    setWebSocketDataMessage([])
                }, 3000);
            }
        }

        webSocket.onerror = () => {
            if (localStorage.getItem('connectStatus') === 'true' && webSocket.readyState === 3) {
                setTimeout(() => {
                    managerSendStatus = true
                    connectToWebSocket();
                    setWebSocketData([])
                    setTimeForRoom([])
                    setActiveSessions([])
                    setPendingChats([])
                    setSupportedChats([])
                    setWebSocketDataMessage([])
                }, 3000);
            }
        }
    }

    useEffect(() => {
        let data = infoData.infoData.option
        data = data && JSON.parse(data)
        if (infoData.infoData.length !== 0 && infoData.infoData.type !== 'admin') {
            let currentTime = new Date()
            return wssStatus &&
            (
                data && data.schedule[currentTime.getDay() - 1]
                    ? parseInt(
                    data && data.schedule[currentTime.getDay() - 1].workStart
                        .slice(0, data && data.schedule[currentTime.getDay() - 1].workStart.indexOf(':')), 10
                    ) <= currentTime.getHours() &&
                    // parseInt(
                    //     data && data.schedule[currentTime.getDay() - 1].workStart
                    //         .slice(data && data.schedule[currentTime.getDay() - 1].workStart.indexOf(':') + 1), 10
                    // ) <= currentTime.getMinutes() &&
                    parseInt(
                        data && data.schedule[currentTime.getDay() - 1].workStop
                            .slice(0, data && data.schedule[currentTime.getDay() - 1].workStop.indexOf(':')), 10
                    ) >= currentTime.getHours()
                    // &&
                    // parseInt(
                    //     data && data.schedule[currentTime.getDay() - 1].workStop
                    //         .slice(data && data.schedule[currentTime.getDay() - 1].workStop.indexOf(':') + 1), 10
                    // ) >= currentTime.getMinutes()
                    : parseInt(
                    infoData.infoData.workStart
                        .slice(0, infoData.infoData.workStart.indexOf(':')), 10
                    ) <= currentTime.getHours() &&
                    parseInt(
                        infoData.infoData.workStop
                            .slice(0, infoData.infoData.workStop.indexOf(':')), 10
                    ) >= currentTime.getHours()
            )
                ? connectToWebSocket()
                : () => {
                    getWebSocket("")
                    setWebSocketData([])
                    setTimeForRoom([])
                    setWebSocketDataMessage([])
                    localStorage.setItem('connectStatus', 'false')
                }
        }
    }, [wssStatus])

    const showRoomNotification = () => {
        let options = {
            title: "Ticket number of new room:",
            body: roomForNotification.ticketNumber,
            dir: "ltr"
        };
        new Notification("New room was created", options);
    }

    useEffect(() => {
        if (roomForNotification !== 'Number does not exist') {
            // return showRoomNotification()
        }
    }, [roomForNotification])

    const showMessageNotification = () => {
        let options = {
            title: "The last message:" + ' ' + messageForNotification.from,
            body: messageForNotification.message,
            dir: "ltr"
        };
        new Notification("New message", options);
    }

    useEffect(() => {
        if (messageForNotification !== 'Message does not exist') {
            return showMessageNotification()
        }
    }, [messageForNotification])

    if (newMessage) {
        wssDataMessage.map(msg => {
            return updateCardDataMessage(msg)
        })
    }

    useEffect(() => {
        wssData.map(item => {
            if (
                localStorage.getItem(item.ticketNumber) === 'pendingChats' &&
                !pendingChats.includes(item.ticketNumber) &&
                !supportedChats.includes(item.ticketNumber)
            ) {
                setPendingChats(prevState => [
                    ...prevState,
                    item.ticketNumber
                ])
            }
            if (
                localStorage.getItem(item.ticketNumber) === 'ticketNumber' &&
                !supportedChats.includes(item.ticketNumber) &&
                !pendingChats.includes(item.ticketNumber)
            ) {
                setSupportedChats(prevState => [
                    ...prevState,
                    item.ticketNumber
                ])
            }
            if (
                !localStorage.getItem(item.ticketNumber) &&
                !activeSessions.includes(item.ticketNumber)
            ) {
                setActiveSessions(prevState => [
                    ...prevState,
                    item.ticketNumber
                ])
            }
        })
    }, [wssDataMessage, wssData, wssDataClosed])

    if (!sessionStatus) {
        deleteCard(wssDataClosed)
    }

    const handleOpenChatWindow = event => {
        event.persist()
        event.target.parentElement.lastChild.classList.remove('joinLink')
        event.target.parentElement.lastChild.classList.add('lastMessage')
        event.target.parentElement.parentElement.childNodes[2].lastChild.innerHTML = 0
        event.target.parentElement.firstChild.firstChild.innerHTML = 0
        setActiveRoomId(event.target.getAttribute('data-id'))
        setActiveSessions(
            activeSessions.filter(activeSession => {
                return activeSession === event.target.getAttribute('data-ticket')
                    ? null
                    : activeSession
            })
        )
        setPendingChats(pendingChats.filter(pendingChat => {
            return pendingChat === event.target.getAttribute('data-ticket')
                ? null
                : pendingChat
        }))
        setActiveChatStatus(true)
        if (!supportedChats.includes(event.target.getAttribute('data-ticket'))) {
            setSupportedChats(prevState => [
                ...prevState,
                event.target.getAttribute('data-ticket')
            ])
        }
        setJoinLink(event.target.parentElement.lastChild.getAttribute('href'))
        setOpenChatWindow(true)
    };

    const activateInfoCard = event => {
        event.persist()
        event.target.classList.remove('joinLink')
        event.target.classList.add('lastMessage')
        event.target.parentElement.parentElement.childNodes[2].lastChild.innerHTML = 0
        event.target.parentElement.firstChild.firstChild.innerHTML = 0
        setActiveRoomId(event.target.parentElement.firstChild.getAttribute('data-id'))
        setActiveSessions(
            activeSessions.filter(activeSession => {
                return activeSession === event.target.parentElement.firstChild.getAttribute('data-ticket')
                    ? null
                    : activeSession
            })
        )
        setPendingChats(
            pendingChats.filter(pendingChat => {
                return pendingChat === event.target.parentElement.firstChild.getAttribute('data-ticket')
                    ? null
                    : pendingChat
            })
        )
        setActiveChatStatus(true)
        if (!supportedChats.includes(event.target.parentElement.firstChild.getAttribute('data-ticket'))) {
            setSupportedChats(prevState => [
                ...prevState,
                event.target.parentElement.firstChild.getAttribute('data-ticket')
            ])
        }
        setJoinLink(event.target.getAttribute('href'))
    }

    useEffect(() => {
        supportedChats.map(item => {
            return item !== null
                ? localStorage.setItem(item, 'ticketNumber')
                : null
        })
    }, [supportedChats])

    const handleCloseChatWindow = () => {
        setOpenChatWindow(false);
    };

    const [playRoomSound] = useSound(room, {volume: volume / 10})

    useEffect(() => {
        if (wssData.length !== 0 && wssStatus) {
            // playRoomSound()
        }
    }, [roomTime])

    const setVolume = event => {
        getVolume(event.target.value)
        localStorage.setItem('volume', event.target.value)
    }

    const decreaseVolume = () => {
        getVolume(prevState => prevState > 1 ? parseInt(prevState) - 1 : prevState)
        localStorage.setItem('volume', volume)
    }

    const increaseVolume = () => {
        getVolume(prevState => prevState < 9 ? parseInt(prevState) + 1 : prevState)
        localStorage.setItem('volume', volume)
    }

    useEffect(() => {
        if (wssData.length !== 0 && wssStatus) {
            playMessageSound()
        }
    }, [volume, wssDataMessage])

    const getAllMessages = msg => {
        setWebSocketDataMessage(prevState => [...prevState, msg])
    }

    const removeManagerMessage = msg => {
        setWebSocketDataMessage(wssDataMessage.filter(item => item.messageId !== msg && msg))
    }

    return (
        <DashboardHandler>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="chatRoomsHandler-modal"
                open={openChatWindow}
                onClose={handleCloseChatWindow}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openChatWindow}>
                    <Chat
                        hash={hash}
                        wss={websocket}
                        joinLink={joinLink}
                        typingUser={typingUser}
                        activeRoomId={activeRoomId}
                        close={handleCloseChatWindow}
                        editedMessages={editedMessages}
                        deletedMessages={deletedMessages}
                        realTimeMessage={wssDataMessage}
                        getAllMessages={getAllMessages}
                        removeManagerMessage={removeManagerMessage}
                    />
                </Fade>
            </Modal>
            {reloadStatus && <ReloadModal/>}
            {
                hash || window.location.search !== '?error'
                    ? null
                    : remove()
            }
            <Header
                currentLanguage={props.currentLanguage}
                infoData={infoData.infoData}
                connectWss={connectWss}
                volume={volume}
                wss={websocket}
                setVolume={setVolume}
                getLang={props.getLang}
                language={props.language}
                currentWidth={currentWidth}
                decreaseVolume={decreaseVolume}
                increaseVolume={increaseVolume}
            />
            {currentWidth > 1024 &&
            <Volume
                volume={volume}
                infoData={infoData.infoData}
                setVolume={setVolume}
                language={props.language}
                decreaseVolume={decreaseVolume}
                increaseVolume={increaseVolume}
            />}
            <ChatRoomsHandler
                wss={websocket}
                wssData={wssData}
                joinLink={joinLink}
                roomTime={roomTime}
                closeCard={closeCard}
                language={props.language}
                activeRoomId={activeRoomId}
                pendingChats={pendingChats}
                cardTakerName={cardTakerName}
                wssDataMessage={wssDataMessage}
                openChatWindow={openChatWindow}
                activeSessions={activeSessions}
                supportedChats={supportedChats}
                categoryHandler={categoryHandler}
                roomEmptyStatus={roomEmptyStatus}
                activeChatStatus={activeChatStatus}
                activateInfoCard={activateInfoCard}
                setActiveChatStatus={setActiveChatStatus}
                handleOpenChatWindow={handleOpenChatWindow}
                handleCloseChatWindow={handleCloseChatWindow}
            />
            <RequestsHistory
                language={props.language}
                sessionStatus={sessionStatus}
                manualSessionStatus={manualSessionStatus}
                getDataOfUsersForFirst={getDataOfUsersForFirst}
                getDataOfUsersForSecond={getDataOfUsersForSecond}
                getDataOfUsersForThird={getDataOfUsersForThird}
                getDataOfUsersForFourth={getDataOfUsersForFourth}
                getDataOfUsersForFifth={getDataOfUsersForFifth}
                getDataOfUsersForSix={getDataOfUsersForSix}
                getDataOfUsersForSeven={getDataOfUsersForSeven}
            />
            <Footer/>
        </DashboardHandler>
    )
}

export default Dashboard