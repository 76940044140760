import React from "react";
import styled from "styled-components"
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import StatisticsTable from "../components/StatisticsTable/StatisticsTable";

const StatisticsHandler = styled.div`
display: flex;
align-items: center;
flex-direction: column;
`

function Statistics(props) {
    return (
        <StatisticsHandler>
            <Header
                infoData={props.infoData}
                language={props.language}
                getLang={props.getLang}
                currentLanguage={props.currentLanguage}
            />
            <StatisticsTable
                language={props.language}
            />
            <Footer/>
        </StatisticsHandler>
    )
}

export default Statistics