import React from "react";
import styled from "styled-components";
import UsersTable from "../components/UsersTable/UsersTable";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const UsersHandler = styled.div`
display: flex;
align-items: center;
flex-direction: column;
`

function Users(props) {
    return (
        <UsersHandler>
            <Header
                infoData={props.infoData}
                language={props.language}
                getLang={props.getLang}
                currentLanguage={props.currentLanguage}
            />
            <UsersTable
                language={props.language}
            />
            <Footer/>
        </UsersHandler>
    )
}

export default Users