import React, {Component} from "react";
import axios from "axios";
import MaterialTable from "material-table";
import Helmet from 'react-helmet';
import {DateUtils} from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import remove from "../StorageClean/remove";
import DayPickerInput from "react-day-picker/DayPickerInput";
import styled from "styled-components"

let hash

hash = {
    "hash": localStorage.getItem('user')
        ? localStorage.getItem('user')
        : localStorage.getItem('userNotRemember')
            ? localStorage.getItem('userNotRemember')
            : window.location.hash.slice(1, 33)
}

const StatisticsHandler = styled.div`
z-index: 0;
width: 90%;
display: flex;
margin-top: 10%;
flex-direction: column;
@media screen and (max-width: 600px) {
margin-top: 20%;
}
`

const StatisticsHandlerDate = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`

export default class StatisticsTable extends Component {
    static defaultProps = {
        numberOfMonths: 2,
    };

    constructor(props) {
        super(props);

        this.state = this.getInitialState();

        this.state = {
            columns: [
                {
                    title: this.props.language.globalStatisticsDate,
                    field: this.props.tableType === 'domain'
                        ? 'domain'
                        : 'date'
                },
                {title: this.props.language.globalStatisticsRooms, field: 'rooms', grouping: false},
                {title: this.props.language.globalStatisticsOnline, field: 'online', grouping: false},
                {title: this.props.language.globalStatisticsCallsManager, field: 'managerCalls', grouping: false},
            ],
            data: []
        }
    }

    getInitialState() {
        return {
            from: undefined,
            to: undefined,
        };
    }

    handleDayClick = async (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        await this.setState(range);
    }

    findDataByDay = () => {
        const {from, to} = this.state

        if (from && to) {
            this.statsRequest(from, to)
            document.querySelectorAll('.MuiTableCell-root').forEach(item => {
                if (item.innerHTML === 'No records to display') {
                    item.innerHTML = this.props.language.noRecords
                }
            })
        }
    }

    handleResetClick = () => {
        this.setState(this.getInitialState());
        this.statsRequest()
    }

    componentDidMount() {
        let first = new Date(),
            second = new Date();
        first.setDate(first.getDate() - 6)
        second.setDate(second.getDate())
        this.statsRequest(first, second)
    }

    statsRequest = (from, to) => {
        axios.post(
            'https://copointer.com:7777/stats',
            {
                hash: Object.values(hash),
                fromDate: from,
                toDate: to,
                group: this.props.tableType === 'domain'
                    ? ["domain"]
                    : ["date"]
            }
        ).then((response) => {
            if (this.props.tableType === 'domain') {
                this.setState({
                    data: response.data
                })
            } else {
                if (from && to) {
                    let startDate = from.toISOString().substr(0, 10)
                    let endDate = to.toISOString().substr(0, 10)

                    this.setState({
                        data: response.data
                            .filter(item => item.date >= startDate && item.date <= endDate)
                    })
                } else {
                    this.setState({
                        data: response.data
                    })
                }
            }

            if (response.data.error === 'WRONGHASH') {
                remove()
            }
        })
    }

    render() {
        const {from, to} = this.state;
        const {tableType} = this.props;
        const modifiers = {start: from, end: to};

        return (
            <StatisticsHandler>
                <StatisticsHandlerDate>
                    <div className="dateRange">
                        <DayPickerInput
                            value={from ? from.toLocaleDateString() : ''}
                            placeholder={this.props.language.selectedFrom}
                            dayPickerProps={{
                                selectedDays: [from, {from, to}],
                                disabledDays: {after: to},
                                toMonth: to,
                                modifiers,
                                numberOfMonths: 1,
                                onDayClick: () => this.to.getInput().focus(),
                            }}
                            onDayChange={this.handleDayClick}
                        />
                        <DayPickerInput
                            ref={el => (this.to = el)}
                            value={to ? to.toLocaleDateString() : ''}
                            placeholder={this.props.language.selectedTo}
                            dayPickerProps={{
                                selectedDays: [from, {from, to}],
                                disabledDays: {before: from},
                                modifiers,
                                month: from,
                                fromMonth: from,
                                numberOfMonths: 1,
                            }}
                            onDayChange={this.handleDayClick}
                        />
                        {from && to && (
                            <button className="link" onClick={this.findDataByDay}>
                                {this.props.language.apply}
                            </button>
                        )}
                        <Helmet>
                            <style>{`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}</style>
                        </Helmet>
                    </div>
                    <CardActions>
                        <Button size="small" color="primary" onClick={this.handleResetClick}>
                            {this.props.language.reset}
                        </Button>
                    </CardActions>
                </StatisticsHandlerDate>
                <MaterialTable
                    title={tableType === 'domain' ? this.props.language.domainStatisticsTitle : this.props.language.globalStatisticsTable}
                    columns={this.state.columns.map((c) => ({...c, tableData: undefined}))}
                    data={this.state.data}
                    options={{
                        grouping: true,
                        pageSize: 10
                    }}
                    style={{width: '100%'}}
                />
            </StatisticsHandler>
        );
    }
}