import React from "react";
import "./Volume.css"
import decrease from "../../assets/img/decrease_volume.svg"
import increase from "../../assets/img/increase_volume.svg"
import GetCode from "../GetCode/GetCode";

function Volume(props) {
    return (
        <div className="volume">
            <h3 className="volume-title">
                {props.language.callRequestVolume}
            </h3>
            <div className="volume-control">
                <img
                    className="volume-control-volume_decrease"
                    src={decrease}
                    alt="Decrease volume"
                    onClick={props.decreaseVolume}
                />
                <input
                    type="range"
                    max="10"
                    step="1"
                    value={props.volume}
                    onChange={(event) => props.setVolume(event)}
                />
                <img
                    className="volume-control-volume_increase"
                    src={increase}
                    alt="Increase volume"
                    onClick={props.increaseVolume}
                />
            </div>
            {
                props.infoData
                ? <GetCode data={props.infoData} language={props.language}/>
                : null
            }
        </div>
    )
}

export default Volume