import React from 'react';
import {render} from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from "./App";

if (window.location.protocol !== 'https:') {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

window.addEventListener('beforeunload', () => {
    localStorage.removeItem('userNotRemember')
})

render(<App/>, document.getElementById('root'));

reportWebVitals();