import React, {Component} from "react";
import '../login/login.css'

export default class CompleteForgotPassword extends Component {
    render() {
        const {language} = this.props
        return (
            <React.Fragment>
                <h2 className="copointerAuth_signIn-title fontForty">
                    {language.thankYou}
                </h2>

                <p className="copointerAuth_signIn-description">
                    {language.thankYouDescription}
                </p>
            </React.Fragment>
        )
    }
}