import React, {Component} from "react";
import axios from "axios";
import remove from "../../StorageClean/remove";
import {lpack} from "../../Language/Language";
import "../CallsToAdminTable.css"
import MaterialTable from "material-table";

let hash;

hash = {
    "hash": window.location.hash.slice(1, 33)
}

if (localStorage.getItem('user')) {
    hash = {
        "hash": localStorage.getItem('user')
    }
} else if (sessionStorage.getItem('user')) {
    hash = {
        "hash": sessionStorage.getItem('user')
    }
}

export default class ChatHistoryTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {title: this.props.language.staffNickName, field: 'from'},
                {
                    title: this.props.language.startSession,
                    field: 'date',
                    render: row => {
                        let date = new Date(row.date)
                        date.setHours(date.getHours() + 2)
                        return date.toLocaleTimeString()
                    }
                },
                {title: this.props.language.message, field: 'Message'},
            ],
            data: []
        }
    }

    componentDidMount() {
        this.getData()
        document.querySelectorAll('.MuiInputBase-input').forEach(item => {
            item.placeholder = this.props.language.search
        })
    }

    getData = async () => {
        document.querySelectorAll('.MuiTableCell-root').forEach(item => {
            if (item.innerHTML === 'No records to display') {
                item.innerHTML = this.props.language.noRecords
            }
        })
        let data = {
            hash: hash.hash,
            roomId: this.props.activeRoomId
        }
        let response = await axios.post('https://copointer.com:7777/chats', data)
        let newData
        for (let i = 0; i < 20; i++) {
            newData = response.data.sort()
        }
        let isEqual = (newData || []) === (this.state.data)
        if (!isEqual) {
            await this.setState({
                data: response.data
            })
        }
        if (response.data.error === 'WRONGHASH') {
            remove()
        }
    }

    render() {
        return (
            <MaterialTable
                title={this.props.language.titleChatHistory}
                columns={this.state.columns}
                data={this.state.data}
                options={{
                    pageSizeOptions: [5]
                }}
            />
        );
    }
}