import React from "react";
import "./Footer.css"

function Footer() {
    return (
        <footer className="footer">
            <p className="footer-text">
                © Co-Browsing.net. 2020
                All rights reserved
            </p>
        </footer>
    )
}

export default Footer