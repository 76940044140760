import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import CopointerAuth from "./views/Auth/Auth";
import {lpack, languagePack} from "./components/Language/Language";
import Dashboard from "./views/Dashboard";
import React, {useEffect, useState} from "react";
import {createBrowserHistory} from "history";
import Statistics from "./views/Statistics";
import Users from "./views/Users";
import axios from "axios";
import remove from "./components/StorageClean/remove";
import Settings from "./components/Settings/Settings";

const hist = createBrowserHistory();

let hash

if (localStorage.getItem('user')) {
    hash = localStorage.getItem('user')
} else if (localStorage.getItem('userNotRemember')) {
    hash = localStorage.getItem('userNotRemember')
} else if (window.location.hash) {
    hash = window.location.hash.slice(1, 33)
    localStorage.setItem('user', window.location.hash.slice(1, 33))
}

function App() {
    const [langPack, setLangPack] = useState(languagePack[navigator.language.split('-')[0].toLowerCase()])
    const [language, setLanguage] = useState(navigator.language.split('-')[0].toUpperCase())
    const [infoData, setInfoData] = useState([]);

    const getLang = lang => {
        if (lang) {
            setLanguage(lang.toUpperCase())
            languagePack[lang]
                ? setLangPack(languagePack[lang])
                : setLangPack(languagePack['en'])
        } else {
            languagePack[navigator.language.split('-')[0].toLowerCase()]
                ? setLangPack(languagePack[navigator.language.split('-')[0].toLowerCase()])
                : setLangPack(languagePack['en'])
        }
    }

    const getInfoData = () => {
        axios.post('https://copointer.com:7777/info', {hash: hash})
            .then(response => {
                setInfoData(response.data)

                if (response.data.error === 'WRONGHASH') {
                    remove()
                }
            })
    }

    const updateData = () => getInfoData()

    useEffect(() => {
        const location = window.location.href
        if (location.includes("/en")) {
            getLang("en")
        } else if (location.includes("/ru")) {
            getLang("ru")
        } else if (location.includes("/ua")) {
            getLang("ua")
        } else {
            getLang(language.toLowerCase())
        }
        getInfoData()
    }, [])

    // const statistics = [
    //     "/en/statistics",
    //     "/ru/statistics",
    //     "/ua/statistics",
    // ]

    const statistics = [
        "/statistics",
        "/statistics",
        "/statistics",
    ]

    // const users = [
    //     "/en/users",
    //     "/ru/users",
    //     "/ua/users",
    // ]

    const users = [
        "/users",
        "/users",
        "/users",
    ]

    // const settings = [
    //     "/en/settings",
    //     "/ru/settings",
    //     "/ua/settings",
    // ]

    const settings = [
        "/settings",
        "/settings",
        "/settings",
    ]

    return (
        <Router history={hist}>
            {
                hash
                    ? <Switch>
                        <Route
                            exact
                            // path={[
                            //     "/en/dashboard",
                            //     "/ru/dashboard",
                            //     "/ua/dashboard",
                            // ]}
                            path={[
                                "/dashboard",
                                "/dashboard",
                                "/dashboard",
                            ]}
                            component={() =>
                                <Dashboard
                                    language={langPack}
                                    getLang={getLang}
                                    infoData={infoData}
                                    currentLanguage={language}
                                />
                            }
                        />
                        {
                            infoData.type === 'admin' &&
                                <>
                                    <Route
                                        path={statistics}
                                        component={() =>
                                            <Statistics
                                                language={langPack}
                                                getLang={getLang}
                                                infoData={infoData}
                                                currentLanguage={language}
                                            />
                                        }
                                    />
                                    <Route
                                        exact
                                        path={users}
                                        component={() =>
                                            <Users
                                                language={langPack}
                                                getLang={getLang}
                                                infoData={infoData}
                                                currentLanguage={language}
                                            />
                                        }
                                    />
                                    <Route
                                        exact
                                        path={settings}
                                        component={() =>
                                            <Settings
                                                language={langPack}
                                                getLang={getLang}
                                                infoData={infoData}
                                                updateData={updateData}
                                                currentLanguage={language}
                                            />
                                        }
                                    />
                                </>
                        }
                        <Route exact path="/">
                            {/*<Redirect to={`/${language.toLowerCase()}/dashboard`}/>*/}
                            <Redirect to={`/dashboard`}/>
                        </Route>
                    </Switch>
                    : <Switch>
                        <Route
                            // path={[
                            //     "/en/auth",
                            //     "/ru/auth",
                            //     "/ua/auth",
                            // ]}
                            path={[
                                "/auth",
                                "/auth",
                                "/auth",
                            ]}
                            component={() =>
                                <CopointerAuth
                                    language={langPack}
                                    currentLanguage={language}
                                />
                            }
                        />
                        {/*<Redirect to={`/${language.toLowerCase()}/auth`}/>*/}
                        <Redirect to={`/auth`}/>
                    </Switch>
            }
        </Router>
    )
}

export default App