import React, {useEffect, useState} from "react";
import "./ReloadModal.css"
import warning from "../../assets/img/warning.svg"

function ReloadModal() {
    const [timeLeft, setTimeLeft] = useState(null);

    useEffect(() => setTimeLeft(3), [])

    useEffect(() => {
        if(timeLeft === 0){
            // window.location.reload()
            setTimeLeft(null)
        }

        if (!timeLeft) return;

        const intervalId = setInterval(() => {

            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return (
        <div className="reload-modal">
            <div className="reload-modal-wrapper">
                <img className="reload-modal-wrapper-img" src={warning} alt="Warning"/>
                <div className="reload-modal-wrapper-info">
                    <h2 className="reload-modal-wrapper-info-title">
                        Reload!
                    </h2>
                    <p className="reload-modal-wrapper-info-description">
                        Sorry for the inconvenience...{timeLeft}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ReloadModal