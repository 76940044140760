import React, {Component} from "react";
import axios from "axios";
import MaterialTable from "material-table";
import styled from "styled-components"

const SitesMessagesOfflineSettingsTableHandler = styled.div`
    z-index: 0;
    width: 100%;
    margin-top: 8%;
`

export default class SitesMessagesOfflineSettingsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {title: this.props.language.staffId, field: 'id', editable: 'never'},
                {title: this.props.language.domainStatisticsDomain, field: 'domain'},
                {
                    title: this.props.language.languageTitle,
                    field: 'lang',
                    validate: rowData => rowData.message === '' ? 'Language cannot be empty' : '',
                },
                {
                    title: this.props.language.message,
                    field: 'message',
                    validate: rowData => rowData.message === '' ? 'Message cannot be empty' : ''
                },
            ],
            data: [],
            hash: localStorage.getItem('user') ? localStorage.getItem('user') : localStorage.getItem('userNotRemember')
        }
    }

    componentDidMount() {
        let options = this.props.infoData.option
        options = options && JSON.parse(options)
        this.setState({
            data: options ? options.sitesDefaultMessagesOffline : [],
        })
    }

    addData = (newData, url) => {
        let hash = {
            "hash": this.state.hash
        }
        let dataMsg = Object.assign({}, newData)
        dataMsg.id !== 0 || dataMsg.id
            ? dataMsg.id = Math.random().toString().slice(2)
            : dataMsg.id = 0
        this.props.addManagersDefaultOfflineMessage(dataMsg)
        return axios.post(url, {
            hash: hash.hash, method: "UPDATE", option: this.props.option
        }).then(() => {
            this.props.updateData()
        })
    }

    updateData = (newData, url) => {
        let hash = {
            "hash": this.state.hash
        }
        this.props.updateManagersDefaultOfflineMessage(newData)
        return axios.post(url, {
            hash: hash.hash, method: "UPDATE", option: this.props.option
        }).then(() => {
            this.props.updateData()
        })
    }

    deleteData = (oldData, url) => {
        let hash = {
            "hash": this.state.hash
        }
        if (oldData.domain !== "default") {
            this.props.deleteManagersDefaultOfflineMessage(oldData)
        }
        return axios.post(url, {
            hash: hash.hash, method: "UPDATE", option: this.props.option
        }).then(() => {
            this.props.updateData()
        })
    }

    render() {
        return (
            <SitesMessagesOfflineSettingsTableHandler>
                <MaterialTable
                    title={this.props.language.sitesMessagesOfflineSettings}
                    columns={this.state.columns}
                    data={this.state.data}
                    editable={{
                        onRowAdd: newData => {
                            return this.addData(newData, 'https://copointer.com:7777/info')
                        },
                        onRowUpdate: newData => {
                            return this.updateData(newData, 'https://copointer.com:7777/info')
                        },
                        onRowDelete: oldData => {
                            return this.deleteData(oldData, 'https://copointer.com:7777/info')
                        }
                    }}
                />
            </SitesMessagesOfflineSettingsTableHandler>
        );
    }
}