import React, {Component} from "react";

import CompleteRegistration from "./completeRegistration";
import AuthService from "../services/auth.service";

import {isEmail} from "validator";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {lpack} from "../../../components/Language/Language"

const required = value => {
    if (!value) {
        return (
            <div className="copointerAuth_signIn-error" role="alert">
                {lpack.fieldRequired}
            </div>
        );
    }
};

const email = value => {
    if (!isEmail(value)) {
        return (
            <div className="copointerAuth_signIn-error" role="alert">
                {lpack.notValidEmail}
            </div>
        );
    }
};

const vpassword = value => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="copointerAuth_signIn-error" role="alert">
                {lpack.notValidPassword}
            </div>
        );
    }
};

export default class Registration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            message: "",
            registration: false,
            status: "",
            passwordCheck: ""
        };
    }

    onChangeEmail = e => {
        this.setState({
            email: e.target.value
        });
    }

    onChangePassword = e => {
        this.setState({
            password: e.target.value
        });
    }

    onChangePasswordCheck = e => {
        this.setState({
            passwordCheck: e.target.value
        });
    }

    handleRegister = e => {
        e.preventDefault();

        this.setState({
            message: ""
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            AuthService.register(
                this.state.email,
                this.state.password
            ).then(
                response => {
                    this.setState({
                        message: response.data.message,
                        status: response.data.status
                    });

                    if (response.data.status === 'Done') {
                        this.activation()
                    }
                }
            );
        }
    }

    render() {
        let {message, status, password, passwordCheck} = this.state
        const {language} = this.props

        return (
            status === "Done"
                ? <CompleteRegistration/>
                : <>
                    <h2 className="copointerAuth_signIn-title">
                        {language.copointerAuthSignUpTitle}
                    </h2>
                    {message && <div className="copointerAuth_signIn-error">{message}</div>}
                    <Form
                        onSubmit={this.handleRegister}
                        ref={c => {
                            this.form = c;
                        }}
                        className="copointerAuth_signIn-form"
                    >
                        <Input
                            type="text"
                            className="copointerAuth_signIn-data"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                            validations={[required, email]}
                            placeholder={language.placeholderSignInEmail}
                        />
                        <Input
                            type="password"
                            className="copointerAuth_signIn-data"
                            name="password"
                            value={this.state.password}
                            onChange={this.onChangePassword}
                            validations={[required, vpassword]}
                            placeholder={language.placeholderSignInPassword}
                        />
                        <Input
                            type="password"
                            className="copointerAuth_signIn-data"
                            name="password"
                            value={passwordCheck}
                            onChange={this.onChangePasswordCheck}
                            placeholder={language.placeholderSignInPassword}
                        />
                        {password !== passwordCheck && <div className="copointerAuth_signIn-error" role="alert">
                            {"Passwords are not similar"}
                        </div>}
                        <CheckButton
                            style={{display: "none"}}
                            ref={c => {
                                this.checkBtn = c;
                            }}
                        />
                        <button
                            disabled={passwordCheck !== password}
                            className="copointerAuth_signIn-button"
                        >
                            {language.copointerAuthSignUpTitle}
                        </button>
                    </Form>
                </>
        )
    }
}