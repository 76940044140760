import React, {useEffect, useState} from "react";
import "./Settings.css"
import remove from "../StorageClean/remove";
import Header from "../Header/Header";
import ManagerMessagesSettingsTable from "../ManagerMessagesSettingsTable/ManagerMessagesSettingsTable";
import SitesMessagesOnlineSettingsTable from "../SitesMessagesOnlineSettingsTable/SitesMessagesOnlineSettingsTable";
import SitesMessagesOfflineSettingsTable from "../SitesMessagesOfflineSettingsTable/SitesMessagesOfflineSettingsTable";
import axios from "axios";
import AnswersForQuestionsTable from "../answersForQuestionsTable/answersForQuestionsTable";

let hash

hash = {
    "hash": localStorage.getItem('user')
        ? localStorage.getItem('user')
        : localStorage.getItem('userNotRemember')
            ? localStorage.getItem('userNotRemember')
            : window.location.hash.slice(1, 33)
}

function Settings(props) {
    let infoData = props.infoData.option
    infoData = infoData && JSON.parse(infoData)
    const [mondayStatus, setMondayStatus] = useState(
        infoData
            ? infoData.schedule[0].closed : false
    )
    const [tuesdayStatus, setTuesdayStatus] = useState(
        infoData
            ? infoData.schedule[1].closed : false
    )
    const [wednesdayStatus, setWednesdayStatus] = useState(
        infoData
            ? infoData.schedule[2].closed : false
    )
    const [thursdayStatus, setThursdayStatus] = useState(
        infoData
            ? infoData.schedule[3].closed : false
    )
    const [fridayStatus, setFridayStatus] = useState(
        infoData
            ? infoData.schedule[4].closed : false
    )
    const [saturdayStatus, setSaturdayStatus] = useState(
        infoData
            ? infoData.schedule[5].closed : true
    )
    const [sundayStatus, setSundayStatus] = useState(
        infoData
            ? infoData.schedule[6].closed : true
    )

    const [mondayWorkStart, setMondayWorkStart] = useState(
        infoData
            ? infoData.schedule[0].workStart : "09:00"
    )
    const [mondayWorkStop, setMondayWorkStop] = useState(
        infoData
            ? infoData.schedule[0].workStop : "18:00"
    )
    const [tuesdayWorkStart, setTuesdayWorkStart] = useState(
        infoData
            ? infoData.schedule[1].workStart : "09:00"
    )
    const [tuesdayWorkStop, setTuesdayWorkStop] = useState(
        infoData
            ? infoData.schedule[1].workStop : "18:00"
    )
    const [wednesdayWorkStart, setWednesdayWorkStart] = useState(
        infoData
            ? infoData.schedule[2].workStart : "09:00"
    )
    const [wednesdayWorkStop, setWednesdayWorkStop] = useState(
        infoData
            ? infoData.schedule[2].workStop : "18:00"
    )
    const [thursdayWorkStart, setThursdayWorkStart] = useState(
        infoData
            ? infoData.schedule[3].workStart : "09:00"
    )
    const [thursdayWorkStop, setThursdayWorkStop] = useState(
        infoData
            ? infoData.schedule[3].workStop : "18:00"
    )
    const [fridayWorkStart, setFridayWorkStart] = useState(
        infoData
            ? infoData.schedule[4].workStart : "09:00"
    )
    const [fridayWorkStop, setFridayWorkStop] = useState(
        infoData
            ? infoData.schedule[4].workStop : "18:00"
    )
    const [saturdayWorkStart, setSaturdayWorkStart] = useState(
        infoData
            ? infoData.schedule[5].workStart : "09:00"
    )
    const [saturdayWorkStop, setSaturdayWorkStop] = useState(
        infoData
            ? infoData.schedule[5].workStop : "18:00"
    )
    const [sundayWorkStart, setSundayWorkStart] = useState(
        infoData
            ? infoData.schedule[6].workStart : "09:00"
    )
    const [sundayWorkStop, setSundayWorkStop] = useState(
        infoData
            ? infoData.schedule[6].workStop : "18:00"
    )

    const [currentWidth, getCurrentWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener("resize", event => {
            getCurrentWidth(event.target.screen.width)
        })
    }, [])

    let option = {
        schedule: [
            {
                day: "monday",
                workStart: mondayWorkStart,
                workStop: mondayWorkStop,
                closed: mondayStatus,
            },
            {
                day: "tuesday",
                workStart: tuesdayWorkStart,
                workStop: tuesdayWorkStop,
                closed: tuesdayStatus,
            },
            {
                day: "wednesday",
                workStart: wednesdayWorkStart,
                workStop: wednesdayWorkStop,
                closed: wednesdayStatus,
            },
            {
                day: "thursday",
                workStart: thursdayWorkStart,
                workStop: thursdayWorkStop,
                closed: thursdayStatus,
            },
            {
                day: "friday",
                workStart: fridayWorkStart,
                workStop: fridayWorkStop,
                closed: fridayStatus,
            },
            {
                day: "saturday",
                workStart: saturdayWorkStart,
                workStop: saturdayWorkStop,
                closed: saturdayStatus,
            },
            {
                day: "sunday",
                workStart: sundayWorkStart,
                workStop: sundayWorkStop,
                closed: sundayStatus,
            },
        ],
        managersDefaultMessages:
            infoData &&
            infoData.managersDefaultMessages &&
            infoData.managersDefaultMessages.length !== 0
                ? [...infoData.managersDefaultMessages]
                : [
                    {
                        id: 0,
                        lang: "default",
                        message: "",
                    },
                ],
        sitesDefaultMessagesOnline:
            infoData &&
            infoData.sitesDefaultMessagesOnline &&
            infoData.sitesDefaultMessagesOnline.length !== 0
                ? [...infoData.sitesDefaultMessagesOnline]
                : [
                    {
                        id: 0,
                        domain: "default",
                        lang: "default",
                        message: "",
                    },
                ],
        sitesDefaultMessagesOffline:
            infoData &&
            infoData.sitesDefaultMessagesOffline &&
            infoData.sitesDefaultMessagesOffline.length !== 0
                ? [...infoData.sitesDefaultMessagesOffline]
                : [
                    {
                        id: 0,
                        domain: "default",
                        lang: "default",
                        message: "",
                    },
                ],
        answersForQuestions:
            infoData &&
            infoData.answersForQuestions &&
            infoData.answersForQuestions.length !== 0
                ? [...infoData.answersForQuestions]
                : [
                    {
                        id: 0,
                        domain: "default",
                        question: "default",
                        answer: "",
                    },
                ],
    }

    const setOption = () => {
        return axios.post("https://copointer.com:7777/info", {
            hash: hash.hash, method: "UPDATE", option: option
        }).then(() => props.updateData())
    }

    const addManagersDefaultMessage = data => option.managersDefaultMessages.push(data)
    const updateManagersDefaultMessage = data => {
        option.managersDefaultMessages = option.managersDefaultMessages.map(msg => {
            return msg.id === data.id
                ? data
                : msg
        })
    }
    const deleteManagersDefaultMessage = data => {
        option.managersDefaultMessages = option.managersDefaultMessages.filter(msg => {
            return msg.id !== data.id
        })
    }

    const addSiteDefaultOnlineMessage = data => option.sitesDefaultMessagesOnline.push(data)
    const updateSiteDefaultOnlineMessage = data => {
        option.sitesDefaultMessagesOnline = option.sitesDefaultMessagesOnline.map(msg => {
            return msg.id === data.id
                ? data
                : msg
        })
    }
    const deleteSiteDefaultOnlineMessage = data => {
        option.sitesDefaultMessagesOnline = option.sitesDefaultMessagesOnline.filter(msg => {
            return msg.id !== data.id
        })
    }

    const addManagersDefaultOfflineMessage = data => option.sitesDefaultMessagesOffline.push(data)
    const updateManagersDefaultOfflineMessage = data => {
        option.sitesDefaultMessagesOffline = option.sitesDefaultMessagesOffline.map(msg => {
            return msg.id === data.id
                ? data
                : msg
        })
    }
    const deleteManagersDefaultOfflineMessage = data => {
        option.sitesDefaultMessagesOffline = option.sitesDefaultMessagesOffline.filter(msg => {
            return msg.id !== data.id
        })
    }

    const addAnswerForQuestion = data => option.answersForQuestions.push(data)
    const updateAnswerForQuestion = data => {
        option.answersForQuestions = option.answersForQuestions.map(msg => {
            return msg.id === data.id
                ? data
                : msg
        })
    }
    const deleteAnswerForQuestion = data => {
        option.answersForQuestions = option.answersForQuestions.filter(msg => {
            return msg.id !== data.id
        })
    }

    return (
        <>
            {
                hash || window.location.search !== '?error'
                    ? null
                    : remove()
            }
            <Header
                currentLanguage={props.currentLanguage}
                infoData={props.infoData}
                // connectWss={connectWss}
                // volume={volume}
                // wss={websocket}
                // setVolume={setVolume}
                getLang={props.getLang}
                language={props.language}
                currentWidth={currentWidth}
                // decreaseVolume={decreaseVolume}
                // increaseVolume={increaseVolume}
            />
            <div className="settings-wrapper">
                <form className="settings-wrapper-form">
                    <div className="settings-wrapper-form-work_days">
                        <div className="settings-wrapper-form-work_days-day_wrapper">
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <label
                                    htmlFor="monday"
                                    className="settings-wrapper-form-work_days-day_wrapper-title"
                                >
                                    {props.language.monday}
                                </label>
                                <p className="settings-wrapper-form-work_days-day_wrapper-checkbox_title">
                                    {props.language.closeStatus}
                                </p>
                                <input
                                    type="checkbox"
                                    className="settings-wrapper-form-work_days-day_wrapper-checkbox"
                                    checked={mondayStatus}
                                    onChange={() => setMondayStatus(!mondayStatus)}
                                />
                            </div>
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <input
                                    id="monday"
                                    type="time"
                                    value={mondayWorkStart}
                                    onChange={e => setMondayWorkStart(e.target.value)}
                                    placeholder="Enter work start time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                                <span className="settings-wrapper-form-work_days-day_wrapper-line">-</span>
                                <input
                                    id="monday"
                                    type="time"
                                    value={mondayWorkStop}
                                    onChange={e => setMondayWorkStop(e.target.value)}
                                    placeholder="Enter work end time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                            </div>
                        </div>
                        <div className="settings-wrapper-form-work_days-day_wrapper">
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <label
                                    htmlFor="tuesday"
                                    className="settings-wrapper-form-work_days-day_wrapper-title"
                                >
                                    {props.language.tuesday}
                                </label>
                                <p className="settings-wrapper-form-work_days-day_wrapper-checkbox_title">
                                    {props.language.closeStatus}
                                </p>
                                <input
                                    type="checkbox"
                                    className="settings-wrapper-form-work_days-day_wrapper-checkbox"
                                    checked={tuesdayStatus}
                                    onChange={() => setTuesdayStatus(!tuesdayStatus)}
                                />
                            </div>
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <input
                                    id="tuesday"
                                    type="time"
                                    value={tuesdayWorkStart}
                                    onChange={e => setTuesdayWorkStart(e.target.value)}
                                    placeholder="Enter work start time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                                <span className="settings-wrapper-form-work_days-day_wrapper-line">-</span>
                                <input
                                    id="tuesday"
                                    type="time"
                                    value={tuesdayWorkStop}
                                    onChange={e => setTuesdayWorkStop(e.target.value)}
                                    placeholder="Enter work end time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                            </div>
                        </div>
                        <div className="settings-wrapper-form-work_days-day_wrapper">
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <label
                                    htmlFor="wednesday"
                                    className="settings-wrapper-form-work_days-day_wrapper-title"
                                >
                                    {props.language.wednesday}
                                </label>
                                <p className="settings-wrapper-form-work_days-day_wrapper-checkbox_title">
                                    {props.language.closeStatus}
                                </p>
                                <input
                                    type="checkbox"
                                    className="settings-wrapper-form-work_days-day_wrapper-checkbox"
                                    checked={wednesdayStatus}
                                    onChange={() => setWednesdayStatus(!wednesdayStatus)}
                                />
                            </div>
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <input
                                    id="wednesday"
                                    type="time"
                                    value={wednesdayWorkStart}
                                    onChange={e => setWednesdayWorkStart(e.target.value)}
                                    placeholder="Enter work start time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                                <span className="settings-wrapper-form-work_days-day_wrapper-line">-</span>
                                <input
                                    id="wednesday"
                                    type="time"
                                    value={wednesdayWorkStop}
                                    onChange={e => setWednesdayWorkStop(e.target.value)}
                                    placeholder="Enter work end time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                            </div>
                        </div>
                        <div className="settings-wrapper-form-work_days-day_wrapper">
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <label
                                    htmlFor="thursday"
                                    className="settings-wrapper-form-work_days-day_wrapper-title"
                                >
                                    {props.language.thursday}
                                </label>
                                <p className="settings-wrapper-form-work_days-day_wrapper-checkbox_title">
                                    {props.language.closeStatus}
                                </p>
                                <input
                                    type="checkbox"
                                    className="settings-wrapper-form-work_days-day_wrapper-checkbox"
                                    checked={thursdayStatus}
                                    onChange={() => setThursdayStatus(!thursdayStatus)}
                                />
                            </div>
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <input
                                    id="thursday"
                                    type="time"
                                    value={thursdayWorkStart}
                                    onChange={e => setThursdayWorkStart(e.target.value)}
                                    placeholder="Enter work start time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                                <span className="settings-wrapper-form-work_days-day_wrapper-line">-</span>
                                <input
                                    id="thursday"
                                    type="time"
                                    value={thursdayWorkStop}
                                    onChange={e => setThursdayWorkStop(e.target.value)}
                                    placeholder="Enter work end time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                            </div>
                        </div>
                        <div className="settings-wrapper-form-work_days-day_wrapper">
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <label
                                    htmlFor="friday"
                                    className="settings-wrapper-form-work_days-day_wrapper-title"
                                >
                                    {props.language.friday}
                                </label>
                                <p className="settings-wrapper-form-work_days-day_wrapper-checkbox_title">
                                    {props.language.closeStatus}
                                </p>
                                <input
                                    type="checkbox"
                                    className="settings-wrapper-form-work_days-day_wrapper-checkbox"
                                    checked={fridayStatus}
                                    onChange={() => setFridayStatus(!fridayStatus)}
                                />
                            </div>
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <input
                                    id="friday"
                                    type="time"
                                    value={fridayWorkStart}
                                    onChange={e => setFridayWorkStart(e.target.value)}
                                    placeholder="Enter work start time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                                <span className="settings-wrapper-form-work_days-day_wrapper-line">-</span>
                                <input
                                    id="friday"
                                    type="time"
                                    value={fridayWorkStop}
                                    onChange={e => setFridayWorkStop(e.target.value)}
                                    placeholder="Enter work end time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                            </div>
                        </div>
                        <div className="settings-wrapper-form-work_days-day_wrapper">
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <label
                                    htmlFor="saturday"
                                    className="settings-wrapper-form-work_days-day_wrapper-title"
                                >
                                    {props.language.saturday}
                                </label>
                                <p className="settings-wrapper-form-work_days-day_wrapper-checkbox_title">
                                    {props.language.closeStatus}
                                </p>
                                <input
                                    type="checkbox"
                                    className="settings-wrapper-form-work_days-day_wrapper-checkbox"
                                    checked={saturdayStatus}
                                    onChange={() => setSaturdayStatus(!saturdayStatus)}
                                />
                            </div>
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <input
                                    id="saturday"
                                    type="time"
                                    value={saturdayWorkStart}
                                    onChange={e => setSaturdayWorkStart(e.target.value)}
                                    placeholder="Enter work start time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                                <span className="settings-wrapper-form-work_days-day_wrapper-line">-</span>
                                <input
                                    id="saturday"
                                    type="time"
                                    value={saturdayWorkStop}
                                    onChange={e => setSaturdayWorkStop(e.target.value)}
                                    placeholder="Enter work end time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                            </div>
                        </div>
                        <div className="settings-wrapper-form-work_days-day_wrapper">
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <label
                                    htmlFor="sunday"
                                    className="settings-wrapper-form-work_days-day_wrapper-title"
                                >
                                    {props.language.sunday}
                                </label>
                                <p className="settings-wrapper-form-work_days-day_wrapper-checkbox_title">
                                    {props.language.closeStatus}
                                </p>
                                <input
                                    type="checkbox"
                                    className="settings-wrapper-form-work_days-day_wrapper-checkbox"
                                    checked={sundayStatus}
                                    onChange={() => setSundayStatus(!sundayStatus)}
                                />
                            </div>
                            <div className="settings-wrapper-form-work_days-day_wrapper-block">
                                <input
                                    id="sunday"
                                    type="time"
                                    value={sundayWorkStart}
                                    onChange={e => setSundayWorkStart(e.target.value)}
                                    placeholder="Enter work start time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                                <span className="settings-wrapper-form-work_days-day_wrapper-line">-</span>
                                <input
                                    id="sunday"
                                    type="time"
                                    value={sundayWorkStop}
                                    onChange={e => setSundayWorkStop(e.target.value)}
                                    placeholder="Enter work end time"
                                    className="settings-wrapper-form-work_days-day_wrapper-input"
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="settings-wrapper-form-submit"
                        onClick={setOption}
                    >
                        {props.language.saveMessage}
                    </button>
                </form>
                <ManagerMessagesSettingsTable
                    option={option}
                    infoData={props.infoData}
                    language={props.language}
                    updateData={props.updateData}
                    addManagersDefaultMessage={addManagersDefaultMessage}
                    updateManagersDefaultMessage={updateManagersDefaultMessage}
                    deleteManagersDefaultMessage={deleteManagersDefaultMessage}
                />
                <SitesMessagesOnlineSettingsTable
                    option={option}
                    infoData={props.infoData}
                    language={props.language}
                    updateData={props.updateData}
                    addSiteDefaultOnlineMessage={addSiteDefaultOnlineMessage}
                    updateSiteDefaultOnlineMessage={updateSiteDefaultOnlineMessage}
                    deleteSiteDefaultOnlineMessage={deleteSiteDefaultOnlineMessage}
                />
                <SitesMessagesOfflineSettingsTable
                    option={option}
                    infoData={props.infoData}
                    language={props.language}
                    updateData={props.updateData}
                    addManagersDefaultOfflineMessage={addManagersDefaultOfflineMessage}
                    updateManagersDefaultOfflineMessage={updateManagersDefaultOfflineMessage}
                    deleteManagersDefaultOfflineMessage={deleteManagersDefaultOfflineMessage}
                />
                <AnswersForQuestionsTable
                    option={option}
                    infoData={props.infoData}
                    language={props.language}
                    updateData={props.updateData}
                    addAnswerForQuestion={addAnswerForQuestion}
                    updateAnswerForQuestion={updateAnswerForQuestion}
                    deleteAnswerForQuestion={deleteAnswerForQuestion}
                />
            </div>
        </>
    )
}

export default Settings