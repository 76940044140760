import React, {Component} from "react";
import "./Auth.css"
import logo from "../../assets/img/logo.svg"
import Login from "./login/login";
import Registration from "./registration/registration";

export default class CopointerAuth extends Component {
    constructor(props) {
        super(props);

        this.toggleSignIn = this.toggleSignIn.bind(this)
        this.toggleSignUp = this.toggleSignUp.bind(this)

        this.state = {
            toggle: false
        }
    }

    toggleSignIn() {
        this.setState({
            toggle: false
        });
    }

    toggleSignUp() {
        this.setState({
            toggle: true
        });
    }

    render() {
        const toggle = this.state.toggle;
        const {language} = this.props

        let button;

        if (toggle) {
            button = <ButtonSignIn onClick={this.toggleSignIn} language={language}/>
        } else {
            button = <ButtonSignUp onClick={this.toggleSignUp} language={language}/>
        }

        return (
            <div className="copointerAuth">
                <a className="copointerAuth_logo" href="https://copointer.com/">
                    <img className="copointerAuth_logo" src={logo} alt="Logo"/>
                </a>
                <div className="copointerAuth_signIn">
                    <Auth
                        toggle={toggle}
                        language={language}
                        currentLanguage={this.props.currentLanguage}
                    />
                    {button}
                </div>
            </div>
        )
    }
}

function Auth(props) {
    const toggle = props.toggle;
    if (toggle) {
        return <Registration
            language={props.language}
            currentLanguage={props.currentLanguage}
        />;
    }
    return <Login
        language={props.language}
        currentLanguage={props.currentLanguage}
    />;
}

function ButtonSignUp(props) {
    return (
        <p className="copointerAuth_signUp" onClick={props.onClick}>
            {props.language.copointerAuthSignUp}
        </p>
    )
}

function ButtonSignIn(props) {
    return (
        <p className="copointerAuth_signUp" onClick={props.onClick}>
            {props.language.copointerAuthSignIn}
        </p>
    )
}
