import React, {Component} from "react";
import axios from "axios";
import MaterialTable from "material-table";
import remove from "../StorageClean/remove";
import styled from "styled-components"

const UsersTableHandler = styled.div`
width: 90%;
z-index: 0;
margin-top: 8%;
`

export default class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {title: this.props.language.staffId, field: 'id', editable: 'never'},
                {title: this.props.language.staffNickName, field: 'NickName', validate: rowData => rowData.NickName === '' ? 'Nickname cannot be empty' : ''},
                {title: this.props.language.staffFirstName, field: 'firstName'},
                {title: this.props.language.staffLastName, field: 'lastName'},
                {title: this.props.language.staffEmail, field: 'email', validate: rowData => rowData.email === '' ? 'Email cannot be empty' : ''},
                {title: this.props.language.staffPassword, field: 'password', validate: rowData => rowData.password === '' ? 'Password cannot be empty' : ''},
                {title: this.props.language.staffStatus, field: 'status', editable: 'never'},
                {title: this.props.language.staffDate, field: 'adate', editable: 'never'}
            ],
            data: [],
            isMounted: false,
            hash: localStorage.getItem('user') ? localStorage.getItem('user') : localStorage.getItem('userNotRemember')
        }
    }

    getData = () => {
        let hash = {
            "hash": this.state.hash
        }
        axios.post('https://copointer.com:7777/users', hash)
            .then((response) => {
                this.setState({
                    data: response.data !== 'Not found' ? response.data : []
                })
                if (response.data.error === 'WRONGHASH') {
                    remove()
                }
                this.setState({
                    isMounted: true
                })
            })
            .catch((err) => {
                console.log('[Users Table get data]: ' + err)
                this.setState({
                    isMounted: true
                })
            });
    }

    addData = (newData, url) => {
        let hash = {
            "hash": this.state.hash
        }
        return axios.post(url, {
            hash: hash.hash, method: "ADD", data: newData
        })
    }

    updateData = (newData, url) => {
        let hash = {
            "hash": this.state.hash
        }
        return axios.post(url, {
            hash: hash.hash, method: "UPDATE", id: newData.id, data: newData
        })
    }

    deleteData = (oldData, url) => {
        let hash = {
            "hash": this.state.hash
        }
        return axios.post(url, {
            hash: hash.hash, method: "DELETE", id: oldData.id
        })
    }

    componentDidMount() {
        this.getData()
    }

    componentWillUnmount() {
        this.setState({
            isMounted: false
        })
    }

    render() {
        const {isMounted} = this.state
        if (!isMounted) return null
        return (
            <UsersTableHandler>
                <MaterialTable
                    title={this.props.language.staffTitle}
                    columns={this.state.columns}
                    data={this.state.data}
                    editable={{
                        onRowAdd: newData => {
                            return this.addData(newData, 'https://copointer.com:7777/users').then(this.getData)
                        },
                        onRowUpdate: newData => {
                            return this.updateData(newData, 'https://copointer.com:7777/users').then(this.getData)
                        },
                        onRowDelete: oldData => {
                            return this.deleteData(oldData, 'https://copointer.com:7777/users').then(this.getData)
                        }
                    }}
                />
            </UsersTableHandler>
        );
    }
}