import React, {useEffect, useState} from "react";
import "./Header.css"
import logo from "../../assets/img/logo.svg"
import exit from "../../assets/img/exit.svg"
import MobileMenu from "./MobileMenu/MobileMenu";
import remove from "../StorageClean/remove";

function Header(props) {
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset)
    const [visible, setVisible] = useState(true)
    let [data, setData] = useState(props);
    const [switchStatus, setSwitchStatus] = useState(
        localStorage.getItem('connectStatus')
            ? localStorage.getItem('connectStatus') === 'true'
            : true
    );
    const [langList, setLangListStatus] = useState(false)
    const [wssStatus, setWssStatus] = useState(
        localStorage.getItem('connectStatus')
            ? localStorage.getItem('connectStatus') === 'true'
            : true
    );
    const [currentPage, getCurrentPage] = useState(window.location.pathname.slice(3))

    const connectWss = connectStatus => {
        setWssStatus(connectStatus)
        localStorage.setItem('connectStatus', connectStatus)
    }

    useEffect(() => {
        setData(props)
    }, [props])

    useEffect(() => {
        let currentTime = new Date()
        let data = props.infoData.option
        data = data && JSON.parse(data)
        // if(data && data.schedule[currentTime.getDay() - 1].closed && localStorage.getItem('connectStatus') === 'false') {
        // if (
        //     (!!data &&
        //     !!data.schedule[currentTime.getDay() - 1]) &&
        //     (parseInt(
        //         data && data.schedule[currentTime.getDay() - 1].workStop
        //             .slice(0, data && data.schedule[currentTime.getDay() - 1].workStop.indexOf(':')), 10
        //     )) >= currentTime.getHours() &&
        //     (parseInt(
        //         data && data.schedule[currentTime.getDay() - 1].workStart
        //             .slice(0, data && data.schedule[currentTime.getDay() - 1].workStart.indexOf(':')), 10
        //     )) <= currentTime.getHours()
        // ) {
            if (
                (data &&
                data.schedule[currentTime.getDay() - 1]) &&
                data.schedule[currentTime.getDay() - 1].closed
            ) {
                setSwitchStatus(false)
                localStorage.setItem('connectStatus', 'false')
                // } else if (data && !data.schedule[currentTime.getDay() - 1].closed && localStorage.getItem('connectStatus') === 'true') {
            } else {
                setSwitchStatus(true)
                localStorage.setItem('connectStatus', 'true')
            }
        // } else {
        //     console.log("FALSE")
        //     localStorage.setItem('connectStatus', 'false')
        // }
    }, [])

    const switchStatusHandler = event => {
        // setSwitchStatus(event.target.checked)
        // props.connectWss(event.target.checked)
        // if (event.target.checked === false) return props.wss && props.wss.close()
        setSwitchStatus(!switchStatus)
        props.connectWss(!switchStatus)
        if (!switchStatus === false) return props.wss && props.wss.close()
    }

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const visibleContent = prevScrollPos > currentScrollPos;

        setPrevScrollPos(currentScrollPos)
        setVisible(visibleContent)
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos])

    useEffect(() => {
        getCurrentPage(window.location.pathname.slice(3))
    }, [window.location])

    const setDataToLocalStorage = () => {
        return data
            ? localStorage.setItem(
                'managerName',
                props.infoData.nickname
                    ? props.infoData.nickname
                    : !props.infoData.nickname
                    ? props.infoData.email
                    : props.infoData.firstName + ' ' + props.infoData.lastName
            )
            : undefined
    }

    if (data !== []) {
        setDataToLocalStorage()
    }

    const langListStatusHandler = () => {
        setLangListStatus(!langList)
    }

    const sendLang = event => {
        props.getLang(event.target.innerHTML.toLowerCase())
        // window.location.assign(window.location.origin + "/" + event.target.innerHTML.toLowerCase() + currentPage)
    }

    // const theme = createMuiTheme({
    //     overrides: {
    //         MuiSwitch: {
    //             switchBase: {
    //                 // Controls default (unchecked) color for the thumb
    //                 color: "#ba0000"
    //             },
    //             colorSecondary: {
    //                 "&$checked": {
    //                     // Controls checked color for the thumb
    //                     color: "#07ce00"
    //                 }
    //             },
    //             track: {
    //                 // Controls default (unchecked) color for the track
    //                 opacity: 0.7,
    //                 backgroundColor: "#ff9d9d",
    //                 "$checked$checked + &": {
    //                     // Controls checked color for the track
    //                     opacity: 0.7,
    //                     backgroundColor: "#a4ffa1"
    //                 }
    //             }
    //         }
    //     }
    // });

    return (
        // <ThemeProvider theme={theme}>
            <div className={`header ${visible ? '' : 'hidden'}`}>
                <MobileMenu
                    visible={visible}
                    volume={props.volume}
                    getLang={props.getLang}
                    infoData={data.infoData}
                    language={props.language}
                    setVolume={props.setVolume}
                    currentWidth={props.currentWidth}
                    decreaseVolume={props.decreaseVolume}
                    increaseVolume={props.increaseVolume}
                    currentLanguage={props.currentLanguage}
                />
                <img className="header-logo" src={logo} alt="Logo"/>
                <div className="header_lang desktop_lang-list">
                    <p
                        className={`header_lang-current 
                    ${langList ? "langList-current-true" : ""}`}
                        onClick={langListStatusHandler}
                    >
                        {props.currentLanguage}
                    </p>
                    <div className={`header_lang-list ${langList ? "langList-true" : "langList-false"}`}>
                        {
                            props.currentLanguage === 'EN'
                                ? <>
                                    <div className={`header_lang-list-item ${props.currentWidth > 1024 ? "white" : ""}`}
                                         onClick={sendLang}>RU
                                    </div>
                                    <div className={`header_lang-list-item ${props.currentWidth > 1024 ? "white" : ""}`}
                                         onClick={sendLang}>UA
                                    </div>
                                </>
                                : props.currentLanguage === 'RU'
                                ? <>
                                    <div className={`header_lang-list-item ${props.currentWidth > 1024 ? "white" : ""}`}
                                         onClick={sendLang}>EN
                                    </div>
                                    <div className={`header_lang-list-item ${props.currentWidth > 1024 ? "white" : ""}`}
                                         onClick={sendLang}>UA
                                    </div>
                                </>
                                : props.currentLanguage === 'UA'
                                    ? <>
                                        <div className={`header_lang-list-item ${props.currentWidth > 1024 ? "white" : ""}`}
                                             onClick={sendLang}>EN
                                        </div>
                                        <div className={`header_lang-list-item ${props.currentWidth > 1024 ? "white" : ""}`}
                                             onClick={sendLang}>RU
                                        </div>
                                    </>
                                    : null
                        }
                    </div>
                </div>
                {window.location.href.includes("/dashboard") && <p className="header-title">
                    {props.language.dashboard}
                </p>}
                {window.location.href.includes("/settings") && <p className="header-title">
                    {props.language.settings}
                </p>}
                {window.location.href.includes("/statistics") && <p className="header-title">
                    {props.language.statistics}
                </p>}
                {window.location.href.includes("/users") && <p className="header-title">
                    {props.language.staff}
                </p>}
                <div
                    className={`header-connect_switch ${switchStatus ? "header-connect_switch-online" : "header-connect_switch-offline"}`}
                    onClick={switchStatusHandler}
                >
                    <p className="header-connect_switch-text">
                        {
                            switchStatus
                            ? props.language.online
                            : props.language.offline
                        }
                    </p>
                    <div className={`header-connect_switch-circle ${!switchStatus && "header-connect_switch-circle_offline"}`}>
                        <div className={`header-connect_switch-circle_inside ${!switchStatus && "header-connect_switch-circle_inside-offline"}`}>

                        </div>
                    </div>
                    {/*<Switch*/}
                    {/*    checked={switchStatus}*/}
                    {/*    onChange={switchStatusHandler}*/}
                    {/*    name="checked"*/}
                    {/*    color="primary"*/}
                    {/*    inputProps={{'aria-label': 'primary checkbox'}}*/}
                    {/*/>*/}
                </div>
                <p className="header-username">
                    {
                        props.infoData
                            ? props.infoData.nickname
                            ? props.infoData.nickname
                            : !props.infoData.nickname
                                ? props.infoData.email
                                : props.infoData.firstName + ' ' + props.infoData.lastName
                            : null
                    }
                </p>
                <button
                    className="header-button"
                    onClick={() => remove('close')}
                >
                    {props.language.logout}
                </button>
                <img
                    className="header-exit"
                    src={exit}
                    alt="Exit"
                    onClick={() => remove('close')}
                />
            </div>
        // </ThemeProvider>
    )
}

export default Header