import React, {Component, useRef} from "react";
import axios from "axios";
import MaterialTable from "material-table";
import remove from "../../components/StorageClean/remove";
import "./CallsToAdminTable.css"
import ChatHistoryTable from "./ChatHistoryTable/ChatHistoryTable";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {DateUtils} from "react-day-picker";
import Helmet from "react-helmet";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import close from "../../assets/img/close.svg"
import DayPickerInput from "react-day-picker/DayPickerInput";
import Tooltip from "@material-ui/core/Tooltip";

let hash

hash = {
    "hash": localStorage.getItem('user')
        ? localStorage.getItem('user')
        : localStorage.getItem('userNotRemember')
            ? localStorage.getItem('userNotRemember')
            : window.location.hash.slice(1, 33)
}

let first = new Date(),
    second = new Date(),
    third = new Date(),
    fourth = new Date(),
    fifth = new Date(),
    six = new Date(),
    seven = new Date();
first.setDate(first.getDate() - 6)
second.setDate(second.getDate() - 5)
third.setDate(third.getDate() - 4)
fourth.setDate(fourth.getDate() - 3)
fifth.setDate(fifth.getDate() - 2)
six.setDate(six.getDate() - 1)
seven.setDate(seven.getDate())

export default class CallsToAdminTable extends Component {
    static defaultProps = {
        numberOfMonths: 2,
    };

    constructor(props) {
        super(props);

        this.state = this.getInitialState();

        this.state = {
            columns: [
                {
                    title: this.props.language.startSession,
                    field: 'date',
                    render: row => row.date.toLocaleDateString()
                },
                {
                    title: this.props.language.ticketNumber,
                    field: 'ticketNumber',
                    render: row =>
                        <p>
                            {row.ticketNumber.slice(0, row.ticketNumber.lastIndexOf('-') + 1)}
                            <b>
                                {row.ticketNumber.slice(row.ticketNumber.lastIndexOf('-') + 1)}
                            </b>
                        </p>
                },
                {
                    title: this.props.language.location,
                    field: 'location',
                    render: row =>
                        // <div className="url-wrapper">
                        //     <button
                        //     className="url-wrapper-button"
                        //         onClick={() => {
                        //         navigator.clipboard.writeText(row.location)
                        //     }}>
                        //         Copy url
                        //     </button>
                        <Tooltip
                            title={
                                this.state.copiedText !== row.location
                                    ? this.props.language.copy
                                    : this.props.language.copied
                            }
                            placement="top"
                        >
                            <p
                                className="url-wrapper-text"
                                onClick={() => {
                                    navigator.clipboard.writeText(row.location)
                                    this.setState({copiedText: row.location})
                            }}>
                                {row.location.slice(0, parseInt(this.state.currentWidth / 50)) + "..."}
                            </p>
                        </Tooltip>
                        // </div>
                },
                {title: this.props.language.sessionDuration, field: 'sessionDuration'},
                {
                    title: this.props.language.titleChatHistory,
                    field: 'roomId',
                    render: row => <div className="roomId" onClick={
                        (event) => {
                            let ticketNumberFirst = event.target.parentElement.parentElement.childNodes[1].firstChild.innerHTML,
                                ticketNumberSecond = event.target.parentElement.parentElement.childNodes[1].firstChild.innerHTML
                            this.setState({
                                roomId: event.target.getAttribute('data-roomid'),
                                chatHistory: true
                            })
                            this.setState(prevState => {
                                return {
                                    selectedRows: [
                                        ...prevState.selectedRows,
                                        ticketNumberFirst
                                            .slice(0, ticketNumberFirst.indexOf('<')) +
                                        ticketNumberSecond
                                            .slice(ticketNumberSecond.indexOf('>') + 1, ticketNumberSecond.lastIndexOf('<'))
                                    ]
                                }
                            })
                            setTimeout(() => this.handleResetClick(), 0)
                        }
                    } data-roomid={row.roomId}>{this.props.language.openChatHistory}</div>
                }
            ],
            data: [],
            roomId: '',
            chatHistory: false,
            selectedRows: [],
            currentWidth: window.innerWidth
        }
    }

    getInitialState() {
        return {
            from: undefined,
            to: undefined,
        };
    }

    handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        return this.setState(range);
    }

    findDataByDay = async () => {
        const {from, to} = this.state
        if (from && to) {
            return await this.statsRequest(from, to)
        }
        document.querySelectorAll('.MuiTableCell-root').forEach(item => {
            if (item.innerHTML === 'No records to display') {
                item.innerHTML = this.props.language.noRecords
            }
        })
    }

    handleResetClick = async () => {
        this.setState(this.getInitialState());
        return await this.statsRequest()
    }

    closeChatHistory = () => {
        this.setState({
            chatHistory: false
        })
    };

    componentDidMount() {
        this.statsRequest()
        document.querySelectorAll('.MuiInputBase-input').forEach(item => {
            item.placeholder = this.props.language.search
        })
        window.addEventListener("resize", event => {
            this.setState({
                currentWidth: event.target.screen.width
            })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sessionStatus !== this.props.sessionStatus) {
            return this.handleResetClick()
        }
        if (prevProps.manualSessionStatus !== this.props.manualSessionStatus) {
            return this.handleResetClick()
        }
    }

    statsRequest = async (from, to) => {
        let response = await axios.post(
            'https://copointer.com:7777/alerts',
            {
                hash: Object.values(hash),
                fromDate: from,
                toDate: to,
                group: ["date"]
            }
        )
        if (response.data.error === "WRONGHASH") {
            remove("close")
        } else if (response.data) {
            let isEqual = (response.data || []).join('') === (this.state.data).join('')
            if (!isEqual) {
                this.props.getDataOfUsersForFirst(response.data.filter(item => Object.values(item)[0] === first.toISOString().substr(0, 10)).length)
                this.props.getDataOfUsersForSecond(response.data.filter(item => Object.values(item)[0] === second.toISOString().substr(0, 10)).length)
                this.props.getDataOfUsersForThird(response.data.filter(item => Object.values(item)[0] === third.toISOString().substr(0, 10)).length)
                this.props.getDataOfUsersForFourth(response.data.filter(item => Object.values(item)[0] === fourth.toISOString().substr(0, 10)).length)
                this.props.getDataOfUsersForFifth(response.data.filter(item => Object.values(item)[0] === fifth.toISOString().substr(0, 10)).length)
                this.props.getDataOfUsersForSix(response.data.filter(item => Object.values(item)[0] === six.toISOString().substr(0, 10)).length)
                this.props.getDataOfUsersForSeven(response.data.filter(item => Object.values(item)[0] === seven.toISOString().substr(0, 10)).length)
            }
            if (from && to) {
                let startDate = from.toISOString().substr(0, 10)
                let endDate = to.toISOString().substr(0, 10)

                await this.setState({
                    data: response.data
                        .filter(item => item.date >= startDate && item.date <= endDate)
                        .map(item => {
                            let date = new Date(item.date)
                            date.setHours(date.getHours() + 2)
                            return {
                                date: date,
                                from: item.from,
                                location: item.location,
                                message: item.message,
                                roomId: item.roomId,
                                sessionDuration: item.sessionDuration,
                                ticketNumber: item.ticketNumber,
                                managerStatus: item.managerStatus,
                                color: item.managerStatus === "No Action"
                                    ? "#ffdd60"
                                    : "white"
                            }
                        })
                })
            } else {
                await this.setState({
                    data: response.data
                        .map(item => {
                            let date = new Date(item.date)
                            date.setHours(date.getHours() + 2)
                            return {
                                date: date,
                                from: item.from,
                                location: item.location,
                                message: item.message,
                                roomId: item.roomId,
                                sessionDuration: item.sessionDuration,
                                ticketNumber: item.ticketNumber,
                                managerStatus: item.managerStatus,
                                color: item.managerStatus === "No Action"
                                    ? "#ffdd60"
                                    : "white"
                            }
                        })
                })
            }
            if (response.data.error === 'WRONGHASH') {
                remove()
            }
        }
    }

    render() {
        const {from, to} = this.state;
        const modifiers = {start: from, end: to};

        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                    open={this.state.chatHistory}
                    onClose={this.closeChatHistory}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.chatHistory}>
                        <div className="flexColumn">
                            <img className="flexEnd" src={close} alt="Close" onClick={this.closeChatHistory}/>
                            <ChatHistoryTable
                                language={this.props.language}
                                activeRoomId={this.state.roomId}
                            />
                        </div>
                    </Fade>
                </Modal>
                <div style={{display: 'flex', flexDirection: 'column', zIndex: '0'}}>
                    <div style={{display: 'inherit', zIndex: '100'}}>
                        <div className="dateRange">
                            <DayPickerInput
                                value={from ? from.toLocaleDateString() : ''}
                                placeholder={this.props.language.selectedFrom}
                                dayPickerProps={{
                                    selectedDays: [from, {from, to}],
                                    disabledDays: {after: to},
                                    toMonth: to,
                                    modifiers,
                                    numberOfMonths: 1,
                                    onDayClick: () => this.to.getInput().focus(),
                                }}
                                onDayChange={this.handleDayClick}
                            />
                            <DayPickerInput
                                ref={el => (this.to = el)}
                                value={to ? to.toLocaleDateString() : ''}
                                placeholder={this.props.language.selectedTo}
                                dayPickerProps={{
                                    selectedDays: [from, {from, to}],
                                    disabledDays: {before: from},
                                    modifiers,
                                    month: from,
                                    fromMonth: from,
                                    numberOfMonths: 1,
                                }}
                                onDayChange={this.handleDayClick}
                            />
                            {from && to && (
                                <CardActions>
                                    <Button size="small" color="primary" onClick={this.findDataByDay}>
                                        {this.props.language.apply}
                                    </Button>
                                </CardActions>
                            )}
                            <Helmet>
                                <style>{`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}</style>
                            </Helmet>
                        </div>
                        <CardActions>
                            <Button size="small" color="primary" onClick={this.handleResetClick}>
                                {this.props.language.reset}
                            </Button>
                        </CardActions>
                    </div>
                    <MaterialTable
                        title={""}
                        columns={this.state.columns.map((c) => ({...c, tableData: undefined}))}
                        data={this.state.data}
                        style={{width: '100%'}}
                        onRowClick={((evt, selectedRow) => {
                            this.setState(prevState => {
                                return {selectedRows: [...prevState.selectedRows, selectedRow.ticketNumber]}
                            })
                        })}
                        options={{
                            rowStyle: rowData => ({
                                backgroundColor: rowData.color
                            })
                        }}
                    />
                </div>
            </>
        );
    }
}