import React from "react";
import "./ChatRoomsMobileCategories.css"
import activeSession from "../../../assets/img/active_session.svg"
import pendingSession from "../../../assets/img/pending_session.svg"
import activeChat from "../../../assets/img/active_chats.svg"

function ChatRoomsMobileCategories(props) {
    return (
        <div className="chatRoomsMobileCategories">
            <div
                data-id="allSessions"
                className={`chatRoomsMobileCategories-category 
                ${props.currentCategory === "allSessions" ? "activeCategory" : ""}`}
                onClick={props.getCategory}
            >
                <p className="chatRoomsMobileCategories-category-text">
                    {props.language.allSessions}
                </p>
            </div>
            {/*<div*/}
            {/*    data-id="activeSessions"*/}
            {/*    className={`chatRoomsMobileCategories-category */}
            {/*    ${props.currentCategory === "activeSessions" ? "activeCategory" : ""}`}*/}
            {/*    onClick={props.getCategory}*/}
            {/*>*/}
            {/*    <img className="chatRoomsMobileCategories-category-img" src={activeSession} alt="Icon"/>*/}
            {/*</div>*/}
            <div
                data-id="pendingChats"
                className={`chatRoomsMobileCategories-category 
                ${props.currentCategory === "pendingChats" ? "activeCategory" : ""}`}
                onClick={props.getCategory}
            >
                <img className="chatRoomsMobileCategories-category-img" src={pendingSession} alt="Icon"/>
            </div>
            <div
                data-id="activeChats"
                className={`chatRoomsMobileCategories-category 
                ${props.currentCategory === "activeChats" ? "activeCategory" : ""}`}
                onClick={props.getCategory}
            >
                <img className="chatRoomsMobileCategories-category-img" src={activeChat} alt="Icon"/>
            </div>
        </div>
    )
}

export default ChatRoomsMobileCategories