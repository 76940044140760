import React from "react";
import "./RequestsHistory.css"
import CallsToAdminTable from "../CallsToAdminTable/CallsToAdminTable";

function RequestsHistory(props) {
    return (
        <div className="requestsHistory">
            <h2 className="requestsHistory-title">
                {props.language.title}
            </h2>
            <CallsToAdminTable
                language={props.language}
                sessionStatus={props.sessionStatus}
                manualSessionStatus={props.manualSessionStatus}
                getDataOfUsersForFirst={props.getDataOfUsersForFirst}
                getDataOfUsersForSecond={props.getDataOfUsersForSecond}
                getDataOfUsersForThird={props.getDataOfUsersForThird}
                getDataOfUsersForFourth={props.getDataOfUsersForFourth}
                getDataOfUsersForFifth={props.getDataOfUsersForFifth}
                getDataOfUsersForSix={props.getDataOfUsersForSix}
                getDataOfUsersForSeven={props.getDataOfUsersForSeven}
            />
        </div>
    )
}

export default RequestsHistory