import React, {Component} from "react";
import "../login/login.css"

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import axios from 'axios';
import {isEmail} from "validator";
import CompleteForgotPassword from "./completeForgotPassword";

const required = value => {
    if (!value) {
        return (
            <div className="copointerAuth_signIn-error" role="alert">
                This field is required!
            </div>
        );
    }
};

const email = value => {
    if (!isEmail(value)) {
        return (
            <div className="copointerAuth_signIn-error" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);

        this.state = {
            email: "",
            message: "",
            login: false,
            repassword: false
        };
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        this.form.validateAll();

        const data = {
            email: this.state.email
        }

        axios.post('https://copointer.com:7777/restore/', data).then(
            () => {
                this.setState({
                    repassword: true
                })
            }
        )
    };

    render() {
        const {language} = this.props
        return (
            <React.Fragment>
                {
                    !this.state.repassword
                        ? <React.Fragment>
                            <h2 className="copointerAuth_signIn-title">
                                {language.copointerAuthSignInForgotPassword}
                            </h2>

                            <Form
                                onSubmit={this.handleSubmit}
                                ref={c => {
                                    this.form = c;
                                }}
                                className="copointerAuth_signIn-form"
                            >
                                <Input
                                    type="text"
                                    className="copointerAuth_signIn-data"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChangeEmail}
                                    validations={[required, email]}
                                    placeholder={language.placeholderSignInEmail}
                                />

                                <button
                                    type={"submit"}
                                    className="copointerAuth_signIn-button"
                                >
                                    {language.copointerForgotPasswordButton}
                                </button>
                            </Form>
                        </React.Fragment>
                        : <CompleteForgotPassword language={language}/>
                }
            </React.Fragment>
        );
    }
}